.filters-title {
  color: #8dc3b6;
  background: inheritrans;
}

.filter-modal .ant-modal-content {
  background-color: #f3f3f3;
}

.filter-modal .ant-modal-header {
  background-color: #f3f3f3;
}

.filter-modal .ant-tabs .ant-tabs-tab {
  flex: auto;
  justify-content: center;
  background: white;
  border-radius: 5px;
}

.filter-modal .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list {
  width: 100%;
}

.filter-btns {
  display: flex;
  justify-content: space-between;
}

.filter-btns .clear-btn {
  background: grey;
  color: white;
  border: none;
  font-weight: normal;
}

.filter-btns .ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
  background: grey;
  color: white;
  border: none;
  font-weight: normal;
}

.right-btns {
  display: flex;
  gap: 15px;
}

@media (max-width: 500px) {
  .filter-btns {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
}

@media (max-width: 665px) {
  .filter-btns {
    margin-top: 40px;
  }

  .custom-dates .ant-space-item {
    padding: 0 1rem;
  }
}
