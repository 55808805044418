.admin-payment-popup {
  cursor: pointer;
}

.inner-tab-payout {
  display: block;
}

tr.sales-paid-list {
  /* background-color: aliceblue; */
}
