/*toolbar*/
.recording-toolbar-container {
  /*background: red;*/
  min-height: 40px;
}

.input-select {
  border: none;
  outline: 1px solid #d9d9d9;
  border-radius: 0 2px 2px 0;
  min-height: 32px;
  padding: 0 10px;
  /*color: #D9D9D9;*/
}

.input-select > select option:disabled {
  color: red;
  font-weight: bold;
}

.search-input {
  border: none;
  outline: 1px solid #d9d9d9;
  border-radius: 0 2px 2px 0;
  min-height: 32px;
}

.input-label {
  background: #fafafa;
  /* box-shadow: inset 1px 0 0 #D9D9D9, inset 0 1px 0 #D9D9D9, inset 0 -1px 0 #D9D9D9; */
  border-radius: 2px 0 0 2px;
  min-height: 32px;
  display: flex;
  align-items: center;
  border: 1px solid #d9d9d9;
  cursor: pointer;
}
.duration-toolbar .input-label:not(:last-child) {
  border-left: none;
}
.clear-btn {
  border: none;
  outline: none;
  min-width: 68px;
  background: #ffffff;
  border-radius: 8px;
  font-weight: 800;
  font-size: 14px;
  line-height: 16px;
  color: #359cd7;
  padding: 8px 15px;
  border: 1px solid #359cd7;
}

.search-btn {
  border: none;
  outline: none;
  min-width: 100px;
  background: #359cd7;
  border-radius: 8px;
  font-weight: 800;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
  padding: 8px 25px;
}

/*===============*/

/*Card*/
.recording-card .product-wrap {
  border: none;
  background: #fff;
  cursor: pointer;
  width: 100%;
  padding: 20px 10px;
  border-radius: 10px;
  height: 100%;
}
.recording-card {
  /* max-width: 315px; */
  border: none;
  background: transparent;
  cursor: pointer;
  /* width: 100%; */
  /* padding: 20px 10px;
    border-radius: 10px; */
  margin-bottom: 20px;
}
.product-recording-wrapper div:empty {
  display: none;
}
.img-thumbnail-container {
  max-width: inherit;
}

.img-thumbnail-container > img {
  max-width: inherit;
  border-radius: 10px;
  max-height: 250px;
  object-fit: contain;
  width: 100%;
}

.img-thumbnail-container > .video-duration {
  position: absolute;
  background: white;
  border-radius: 4px;
  padding: 0 8px;
  bottom: 10px;
  right: 15px;
}

.selected-tab {
  background: #359cd7;
  color: white;
}
@media only screen and (max-width: 600px) {
}
