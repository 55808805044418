.subscription-modal-body {
  color: #23265f;
  text-align: center;
  font-family: 'Muslish', sans-serif;
  font-size: 23px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}

.subscription-modal-buttons {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 30px;
  gap: 20px;
}

.cancel-sub-modal-button {
  width: 180px;
  height: 40px;
  flex-shrink: 0;
  border-radius: 10px;
  border: 1px solid #23265f;
  color: #23265f;
  font-family: 'Muslish', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.sub-selection-button {
  width: 180px;
  height: 40px;
  flex-shrink: 0;
  border-radius: 10px;
  background: #23265f;
  color: #fff;
  font-family: 'Muslish', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
  border: 1px solid #23265f;
  color: #23265f;
}

.sub-selection-button:hover {
  background: inherit !important;
  color: #fff;
}

.subscription-modal .ant-modal-content {
  padding: 30px 50px;
}

.pause-sub-body {
  color: #23265f;
  text-align: center;
  font-family: 'Muslish', sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 10px;
}
