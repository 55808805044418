.filter-bar {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: stretch;
  padding: 10px 20px;
  gap: 16px;
  background-color: #fff;
  width: 100%;
  overflow: visible;
}

.filter-item {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-width: 80px;
  padding: 12px 16px;
  background: #ffffff;
  border-radius: 8px;
  border: 1px solid #E5E7EB;
  cursor: pointer;
  overflow: visible;
  height: auto; /* Allow dynamic height adjustment */
}

.filter-item:hover {
  background-color: #F9FAFB;
  transition: background-color 0.2s ease;
}

.filter-label {
  font-weight: 600;
  color: #228b22;
  margin-bottom: 4px;
  font-size: 14px;
  line-height: 1.2;
  width: 100%;
  white-space: normal;
  overflow-wrap: break-word;
  word-break: break-word;
  text-align: justify;
  text-justify: inter-word;
  display: flex;
  justify-content: space-between;
}

.filter-value {
  color: #666666;
  font-size: 14px;
  line-height: 1.2;
  width: 100%;
  white-space: normal;
  overflow-wrap: break-word;
  word-break: break-word;
}

@media (max-width: 1024px) {
  .filter-bar {
      padding: 5px 10px;
      gap: 8px;
  }

  .filter-item {
      padding: 8px 12px;
  }

  .filter-label, 
  .filter-value {
      font-size: 13px;
  }
}

@media (max-width: 768px) {
  .filter-item {
      width: 100%;
      padding: 6px 10px;
  }

  .filter-label, 
  .filter-value {
      font-size: 12px;
  }
}

.expanded .filter-item {
  align-items: stretch;
}
