.forgot-password-wrapper,
.change-password-wrapper {
  margin-top: 5rem;
}

.image-logo-pb {
  height: 40px;
}

.change-password-submit {
  background: #359cd7;
  border: none;
  border-radius: 15px;
  box-shadow: 0 2px 1px rgb(0 0 0 / 12%);
  font-family: Mulish, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  min-height: 50px;
  min-width: 150px;
  padding: 15px 10px;
  text-align: center;
}

p.link-color span {
  padding-left: 3px;
  padding-top: 1px;
  color: #359cd7;
}
.forgot-password-wrapper,
.reset-password-wrapper {
  /* background: #fff; */
  max-width: 800px;
  width: calc(100% - 20px);
}
.information-wrapper,
.instruction-wrapper {
  /* max-width: 500px; */
  padding: 40px 20px 30px;
}
.information-wrapper .password-enter-wrapper,
.instruction-wrapper .password-enter-wrapper {
  max-width: 375px;
}
.reset-password-title {
  margin: 20px 0 32px;
}
.forgot-password-form-wrapper input.form-control {
  max-width: 590px;
}
.forgot-password-form-wrapper .invalid-feedback {
  margin-bottom: 15px;
}
.reset-password-title h3 {
  font-family: "Outfit", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  color: #464444;
}
.forgot-password-form-wrapper input.form-control,
.reset-form-wrapper input.form-control {
  margin-bottom: 15px;
  font-size: 14px;
  min-height: 40px;
  font-family: "Mulish", sans-serif;
  font-weight: 400;
  color: #000;
  background: #ffffff;
  border: 1px solid #ededed;
  border-radius: 15px;
  height: 55px;
}
.return-link-wrapper p {
  font-size: 15px;
  margin-bottom: 24px;
}
.button-wrapper button.btn.btn-primary,
.forgot-password-wrapper button,
.change-password-wrapper button {
  min-height: 40px;
  padding: 12px;
  font-size: 16px;
  font-family: "Mulish", sans-serif;
  font-weight: 700;
  color: #fff;
}
.forgot-password-wrapper,
.change-password-wrapper {
  /* background-color: #fff; */
  margin: 20px auto;
  padding: 50px 20px;
}
.forgot-password-wrapper input,
.change-password-wrapper input {
  font-size: 14px;
  min-height: 40px;
  font-family: "Mulish", sans-serif;
  font-weight: 400;
  color: #000;
  background: #ffffff;
  border: 1px solid #ededed;
  border-radius: 15px;
  height: 55px;
}
.change-password-wrapper button {
  color: #fff;
  padding: 14px;
  margin-top: 15px;
}
@media only screen and (max-width: 768px) {
  .forgot-password-wrapper,
  .change-password-wrapper {
    margin-top: 1.5rem;
  }
  footer img.Header-img {
    width: 100%;
    max-width: 140px;
    margin-bottom: 10px;
  }
}
