.store-admin-payment {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 25px;
  margin-top: 40px;
}

.invite-text {
  color: #000;
  font-family: Inter, sans-serif;
  font-size: 20px;
  font-style: normal;
}

.admin-sign-up-btn {
  border-radius: 5px;
  background: #359cd7;
  color: #fff;
  font-family: 'Mulish', Inter, sans-serif;
  font-style: normal;
  font-weight: 700;
  padding: 0 30px;
  height: 40px;
}

.wallet-card {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 50px;
  margin-bottom: 40px;
  flex-wrap: wrap;
}

.wallet-amount {
  font-weight: 700;
  font-size: 30px;
}

.account-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px;
  border-radius: 10px;
  background: #f2f2f2;
}

.bank-details-container {
  margin-top: 30px;
}

.add-funds-btn {
  background: #871eff;
  color: white;
}

.add-funds-section
  .ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
  background: #871eff;
  color: white;
}

.add-funds-btn svg {
  vertical-align: baseline;
}

.review-text {
  margin-bottom: 20px;
}
