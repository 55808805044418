.notification-whole-wrapper {
  display: block;
  height: calc(100vh - 100px);
  overflow: hidden;
}

.notification-item {
  background: #ffffff;
  border-radius: 16px;
  border: none;
  height: calc(100vh - 205px);
  overflow-y: auto;
}

.notification-user-photo {
  border-radius: 50%;
  height: 30px;
  width: 30px;
  margin-right: 15px;
}
.notification-head h4 {
  font-family: "Mulish", sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 30px;
  color: #181818;
}
.notification-items-wrapper .name-wrapper p {
  font-family: "Mulish", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #181818;
}
.main-message-notification p {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #181818;
}
.main-message-notification p strong {
  font-family: mulish, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  color: #181818;
}
.notification-list-page {
  cursor: pointer;
}

.notification-list-page.d-flex.justify-content-center {
  color: #33a0d8;
  font-size: inherit;
  font-family: "Mulish", sans-serif;
}
