.store-payout-history-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  height: 100%;
  background: #ffffff;
  border-radius: 18px;
}

.product-image-circle {
  height: 150px;
  width: 150px;
  border: none;
  border-radius: 10px;
  object-fit: cover;
}
.store-payout-history-wrapper .table-responsive-lg {
  width: 100%;
}
.store-payout-history-wrapper .table-responsive-lg tbody,
.store-payout-history-wrapper .table-responsive-lg tbody td {
  vertical-align: middle;
}
.store-payout-history-wrapper .table-responsive-lg tbody td {
  cursor: pointer;
}
.store-payout-history-wrapper .table-responsive-lg tbody td .row.d-flex {
  align-items: center;
}

.filter-btn {
  display: flex;
  justify-content: end;
}

.filter-btn .btn.btn-primary {
  background: none;
  border: none;
  min-width: none;
  padding: 0;
  color: black;
}

.coupon-details {
  display: flex;
  gap: 10px;
}

.coupon-details-text {
  display: flex;
  align-items: center;
}

.coupon-details-text button {
  height: 1.5rem;
}

.coupon-details-text button:focus,
.coupon-details-text button:hover {
  color: inherit;
  box-shadow: none;
}

.product-details {
  display: flex;
  gap: 10px;
}

.coupon-header {
  color: #292d32;
  text-align: center;
  font-family: "Mulish", Inter, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
}

.product-name-header {
  color: #292d32;
  text-align: center;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
}

.image-product-square {
  text-align: left;
}

.product-image-host-guest {
  display: flex;
  gap: 30px;
  align-items: center;
  flex-wrap: wrap;
}

.host-details {
  display: flex;
  flex-direction: column;
  align-items: start;
}

.host-custom-img {
  height: 56px;
  width: 56px;
  border-radius: 50%;
  object-fit: cover;
}

.host-detail {
  display: flex;
  gap: 5px;
}

.host-image-details {
  font-size: 14px;
}

.demo-details-modal.modal .modal-dialog {
  max-width: 800px;
}

.video-container {
  width: 50%;
  min-width: 45%;
}

.video-earning-status {
  display: flex;
  align-items: start;
  gap: 50px;
}

.earnings-header {
  color: #292d32;
  text-align: center;
  font-family: Inter, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
}

.earning-status p {
  font-size: 18px;
  font-weight: normal;
}

.earning-status svg {
  vertical-align: baseline;
}

.date-time-display,
.host-joined-display,
.no-show-display,
.guest-joined-display,
.guest-left-display,
.host-left-display,
.face-time-duration-display,
.video-duration-display {
  display: flex;
  justify-content: space-between;
}

.detail-payment-store-wrapper {
  margin-bottom: 20px;
}

.guest-detail-lineitem {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0;
  font-size: 14px;
}

@media (max-width: 600px) {
  .video-earning-status {
    flex-direction: column;
    align-items: center;
    gap: 50px;
  }
  .video-container {
    width: 100%;
  }
}
