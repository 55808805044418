.admin-dashboard-list {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 0px 20px;
  height: 100%;
  background: #ffffff;
  border-radius: 18px;
}

img.csv-download {
  height: 40px;
  width: 40px;
  cursor: pointer;
  object-fit: contain;
}
.admin-dashboard-list th > * {
  font-family: "Mulish", Inter, sans-serif;
}
.admin-dashboard-list th {
  padding: 15px 26px;
  font-family: "Mulish", Inter, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 1.31484px;
  text-transform: capitalize;
  color: #374151;
  vertical-align: middle;
}
.admin-dashboard-list td {
  font-family: "Mulish", Inter, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  padding: 26px;
  color: #374151;
  vertical-align: middle;
}
.arrow-grid svg {
  cursor: pointer;
  width: 0.8rem;
  height: 0.8rem;
}
.csv-wrapper.text-end {
  padding-bottom: 20px;
}
.csv-datepicker input.csv-calendar.mb-3 {
  border: 1px solid #464444;
  border-radius: 15px;
  padding: 15px;
  max-width: 330px;
  width: 100%;
}
.csv-datepicker button.mb-3 {
  max-width: 224px;
  height: 55px;
  background: #359cd7;
  box-shadow: 0px 2px 1px rgb(0 0 0 / 12%);
  border-radius: 15px;
  border: none;
  color: #fff;
  font-family: "Mulish", Inter, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
  text-align: center;
  width: 100%;
}

tr.row-company-table:hover {
  background: aliceblue;
  cursor: pointer;
}
