.subscription-page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.plans-container {
  display: flex;
  justify-content: center;
  align-items: start;
  flex-wrap: wrap;
  gap: 50px;
  border-radius: 20px;
  background: white;
  padding: 50px 0;
}

.individual-plan-container {
  text-align: center;
  max-width: 270px;
  padding: 20px;
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  box-shadow: 0px 4px 5px 1px rgba(193, 193, 193, 0.35);
  position: relative;
}

.active-plan-container {
  text-align: center;
  max-width: 270px;
  padding: 20px;
  border: 1px solid #dbdbdb;
  background: #23265f;
  box-shadow: 0px 4px 5px 1px rgba(193, 193, 193, 0.35);
}

.plan-text-styling {
  color: #23265f;
  text-align: center;
  font-family: "Mulish", sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: normal;
  padding: 5px 0;
}

.active-plan-styling {
  color: #fff;
  text-align: center;
  font-family: "Mulish", sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: normal;
  padding: 5px 0;
}

.plan-name {
  color: #23265f;
  text-align: center;
  font-family: "Mulish", sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: normal;
}

.plan-monthly-fees {
  color: #23265f;
  text-align: center;
  font-family: "Mulish", sans-serif;
  font-size: 54px;
  font-weight: 800;
  line-height: normal;
}

.plan-duration {
  color: #23265f;
  text-align: center;
  font-family: "Mulish", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
}

.partnership-fees {
  color: #23265f;
  text-align: center;
  font-family: "Mulish", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
}

.active-plan-name {
  color: #fff;
  text-align: center;
  font-family: "Mulish", sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: normal;
}

.active-plan-monthly-fees {
  color: #fff;
  text-align: center;
  font-family: "Mulish", sans-serif;
  font-size: 54px;
  font-weight: 800;
  line-height: normal;
}

.active-plan-duration {
  color: #fff;
  text-align: center;
  font-family: "Mulish", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
}

.active-partnership-fees {
  color: #fff;
  text-align: center;
  font-family: "Mulish", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
}

.plan-seperator {
  height: 1px;
  border-top: 1px solid black;
  margin: 12px auto;
  max-width: 100px;
}

.active-plan-seperator {
  height: 1px;
  border-top: 1px solid white;
  margin: 12px auto;
  max-width: 100px;
}

.plan-button {
  width: 100%;
  height: 52px;
  flex-shrink: 0;
  border-radius: 3px;
  background: #23265f;
  color: #fff;
  text-align: center;
  font-family: "Mulish", sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: normal;
  margin-top: 10px;
}

.active-plan-button {
  width: 100%;
  height: 52px;
  flex-shrink: 0;
  border-radius: 3px;
  background: #fff;
  color: #23265f;
  text-align: center;
  font-family: "Mulish", sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: normal;
  margin-top: 10px;
}

.paused-plan-button {
  background-color: red;
  color: white;
}

.individual-plan-container.coming-soon .plan-content {
  filter: blur(3px);
  position: relative;
  pointer-events: none;
}

.coming-soon-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border: 1px solid #23265f;
  color: #23265f;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 18px;
  font-weight: bold;
  z-index: 1;
  width: 80%;
  height: 52px;
}

.manage-plan-btn {
  color: #23265f;
  font-family: "Mulish", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border-radius: 10px;
  border: 1px solid #23265f;
  height: 51px;
  width: 100%;
  margin-top: 15px;
}

.paused-subscription-alert {
  background-color: red;
  width: 100%;
  color: white;
  margin: auto;
  border-radius: 10px;
  padding: 5px 0;
  text-align: center;
  font-family: 'Mulish', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
}

.resume-subscription-link {
  background: none;
  border: none;
  color: #fff;
  font-family: 'Mulish', sans-serif;
  font-size: 20px;
  font-weight: 900;
  line-height: normal;
  text-decoration: underline;
  margin-left: 12px;
}
