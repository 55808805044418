.my-showroom-dashelement {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 52px 20px 64px 20px;
  height: 100%;
  background: #ffffff;
  border-radius: 18px;
  min-height: 562px;
}

.features.add-product-initial {
  justify-content: center;
}

.search-and-add .add-product {
  min-height: 15px;
  height: 40px;
  padding: 0;
}

.search-and-add {
  padding: 20px 0;
}

.features.product-list-dynamic {
  padding: 20px 50px 20px 50px;
  max-width: 1000px;
  margin: 0px auto;
}
.text-wrapper-productadder h3 {
  font-family: "Mulish", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  line-height: 45px;
  margin-bottom: 20px;
}
.text-wrapper-productadder p {
  /* font-family: 'Open Sans'; */
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
}
.button-wraper-productadder a.btn.btn-primary {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 170.23%;
  padding: 8px 20px;
  min-height: 43px;
  background: #359cd7;
  border-radius: 6px;
  box-shadow: none;
}
.search-and-add.text-end {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

ul.product-nav li.nav-link {
  color: #23265f;
  padding: 0.5rem;
  font-family: "Mulish", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
}

li.nav-link.active-selection {
  color: #76bbf7 !important;
  text-decoration: underline;
}

button.search.magnifier {
  border: none;
  background: none;
  margin: 0 8px;
}

button.previous-button-product {
  font-family: "Mulish", sans-serif;
  font-style: normal;
  font-weight: 800;
  margin: 0 5px;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: #fff;
  padding: 10px 0px;
  background-color: #359cd7;
  border-radius: 15px;
  min-width: 100px;
  border: none;
}
button.previous-button-product:disabled {
  background-color: rgb(178, 175, 175);
  pointer-events: none;
}
button.next-button-product {
  font-family: "Mulish", sans-serif;
  font-style: normal;
  font-weight: 800;
  margin: 0 5px;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: #fff;
  padding: 10px 0px;
  background-color: #359cd7;
  border-radius: 15px;
  min-width: 100px;
  border: none;
}
button.next-button-product:disabled {
  background-color: rgb(178, 175, 175);
  pointer-events: none;
}
.search-and-add.row.d-flex {
  flex-wrap: wrap;
}
.search-wrapper input.form-control {
  width: 100%;
}
.add-product-button-wrapper {
  max-width: 175px;
}
.suggested-product-lists button.btn.btn-primary.add-product {
  width: 100%;
  height: 100%;
  min-height: 85px;
  position: relative;
  display: block;
  padding: 0.5rem 1rem;
  color: rgba(0, 0, 0, 0.8);
  text-decoration: none;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0;
  cursor: pointer;
}
@media only screen and (max-width: 600px) {
  .search-wrapper.text-end {
    margin-bottom: 20px;
  }
  .add-product-button-wrapper button.btn.btn-primary.add-product {
    width: 100%;
  }
}
@media only screen and (max-width: 768px) {
  button.btn.btn-primary.add-product {
    min-width: 100%;
    padding: 4px;
    font-size: 14px;
  }
}
.photo-viewing,
.video-wrapper {
  width: 100%;
}
.photo-view-product {
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #ffffff;
  border-radius: 18px;
  padding: 10px 10px;
  margin-bottom: 10px;
}
.questions-view-product {
  display: flex;
  flex-direction: row;
  background: #ffffff;
  border-radius: 18px;
  padding: 20px;
  /* height: 500px; */
  overflow-y: auto;
}

.btn-secondary_custom {
  color: #fff;
  background-color: #2d9dd7;
  border-color: #2d9dd7;
  width: min-content;
  padding: 10px;
  border-radius: 12px;
}
.delete-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
}
.delete-wrap svg {
  cursor: pointer;
  width: 20px;
  height: 20px;
}
