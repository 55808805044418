.filter-bar {
    display: flex;
    flex-wrap: wrap; /* Allow wrapping on smaller screens */
    justify-content: space-between;
    align-items: stretch;
    padding: 10px 20px;
    gap: 16px;
    min-height: fit-content;
    background-color: #fff;
    width: 100%;
    overflow: visible;
}

.filter-item {
    flex: 1 1 150px; /* Allow items to shrink and grow as needed */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 12px 16px;
    background: #ffffff;
    border-radius: 8px;
    border: 1px solid #E5E7EB;
    cursor: pointer;
    height: auto;
    overflow: visible;
    min-width: 120px; /* Prevent items from getting too small */
}

.filter-label {
    font-weight: 600;
    color: #228b22;
    margin-bottom: 4px;
    width: 100%;
    white-space: normal;
    overflow-wrap: break-word;
    word-break: break-word;
    hyphens: auto;
    font-size: 14px;
    line-height: 1.2;
}

.filter-value {
    color: #666666;
    width: 100%;
    white-space: normal;
    overflow-wrap: break-word;
    word-break: break-word;
    hyphens: auto;
    font-size: 14px;
    line-height: 1.2;
    overflow: visible;
}

/* Hover state */
.filter-item:hover {
    background-color: #F9FAFB;
    transition: background-color 0.2s ease;
}

/* Tablet view adjustments */
@media (max-width: 1024px) {
    .filter-bar {
        padding: 10px;
        gap: 12px;
    }

    .filter-item {
        padding: 8px 12px;
    }

    .filter-label, 
    .filter-value {
        font-size: 13px;
    }
}

/* Mobile view adjustments */
@media (max-width: 768px) {
    .filter-bar {
        flex-direction: column; /* Stack filter items vertically */
        align-items: stretch; /* Stretch items to fill width */
        gap: 10px;
    }

    .filter-item {
        width: 100%; /*Full width for mobile*/
        padding: 10px 14px;
    }

    .filter-label, 
    .filter-value {
        font-size: 12px;
    }
}
