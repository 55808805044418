.status-filter {
  background: white;
  display: flex;
  gap: 30px;
  border-radius: 10px;
  padding: 10px 0;
}

.predefined-status .ant-col {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.filter-modal {
  width: 800px !important;
}

.filter-modal .ant-modal-body {
  height: 400px;
}

.custom-status .ant-picker-date-panel {
  width: 240px;
}

.custom-status .ant-picker-range {
  min-width: 480px;
}

@media (max-width: 760px) {
  .custom-status .ant-picker-date-panel {
    width: 230px;
  }

  .custom-status .ant-picker-range {
    min-width: 400px;
  }
}

@media (max-width: 760px) {
  .custom-status .ant-picker-date-panel {
    width: 230px;
  }

  .custom-status .ant-picker-range {
    min-width: 400px;
  }
}

@media (max-width: 665px) {
  .status-filter {
    flex-direction: column;
  }
}

@media (max-width: 490px) {
  .custom-status .ant-picker-date-panel {
    width: 250px;
  }

  .custom-status .ant-picker-range {
    min-width: 250px;
  }

  .custom-status .ant-picker-panels > *:first-child button.ant-picker-header-next-btn {
    visibility: visible;
  }

  .custom-status .ant-picker-panels > *:first-child button.ant-picker-header-super-next-btn {
    visibility: visible;
  }

  .custom-status .ant-picker-panels > *:last-child {
    display: none;
  }

  .custom-status .ant-picker-footer-extra > div {
    flex-wrap: wrap;
  }
}
