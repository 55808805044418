.transcript-container {
  margin-bottom: 10px;
}

.transcript-speaker-header {
  font-weight: 600;
  margin-right: 10px;
}

.ant-collapse-header {
  font-weight: 600;
}
