.plan-manager-container {
  width: 1218px;
  margin: auto;
  background: white;
  padding: 30px 20px;
  border-radius: 20px;
  margin-top: 30px;
}

.sub-plan-header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.sub-plan-main-header {
  color: #23265f;
  text-align: center;
  font-family: 'Mulish', sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}

.sub-plan-sub-header {
  color: #23265f;
  text-align: center;
  font-family: 'Mulish', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.sub-plan-main-header,
.sub-plan-sub-header {
  text-align: left;
}

.sub-plan-manage-buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.sub-plan-action-btn,
.sub-plan-billing-btn {
  width: 224px;
  height: 45px;
  border-radius: 10px;
  border: 1px solid #23265f;
  color: #23265f;
  font-family: 'Mulish', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.sub-plan-details {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 30px;
  margin-top: 20px;
}

.sub-plan-info,
.sub-plan-statistics {
  flex-grow: 1;
  border-radius: 10px;
  border: 1px solid #23265f;
  padding: 20px;
  max-width: 50%;
}

.sub-plan-info {
  max-width: 47%;
}

.sub-plan-info-header,
.sub-plan-statistics-header,
.sub-plan-usage-header {
  color: #23265f;
  font-family: 'Mulish', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.sub-plan-info-details,
.sub-plan-statistics-details,
.sub-plan-usage-calls {
  color: #23265f;
  font-family: 'Mulish', sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 5px;
}

.sub-plan-usage {
  margin-top: 20px;
  border-radius: 10px;
  border: 1px solid #23265f;
  padding: 20px;
}

.sub-plan-usage-calls {
  text-align: center;
}

.progress-bar-container {
  margin-top: 20px;
}

.cancel-sub-buttons {
  display: flex;
  gap: 20px;
  justify-content: flex-end;
  margin-top: 20px;
  flex-wrap: wrap;
}

.cancel-manage-btn,
.cancel-subscription-btn {
  width: 224px;
  height: 45px;
  border-radius: 10px;
  border: 1px solid #23265f;
  color: #23265f;
  font-family: 'Mulish', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.cancel-subscription-btn {
  color: white;
  background: #23265f;
}

.manage-billing-icon {
  margin-left: 5px;
}

@media (max-width: 1500px) {
  .sub-plan-manage-buttons {
    margin-top: 10px;
  }
}

@media (max-width: 1500px) and (min-width: 1200px) {
  .plan-manager-container {
    width: 800px;
  }
}

@media (max-width: 1200px) and (min-width: 100px) {
  .plan-manager-container {
    width: 600px;
  }
  .sub-plan-details {
    flex-direction: column;
  }
  .sub-plan-info,
  .sub-plan-statistics {
    flex-grow: 1;
    border-radius: 10px;
    border: 1px solid #23265f;
    padding: 20px;
    max-width: 100%;
  }
}

@media (max-width: 1000px) {
  .plan-manager-container {
    width: auto;
  }
}
