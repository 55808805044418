@import url("https://fonts.googleapis.com/css2?family=Mulish:wght@300;400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");
.landing-para {
  font-family: "Outfit", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: -0.011em;
  color: rgba(0, 0, 0, 0.8);
}

.home-para {
  text-align: justify;
}

.leftone {
  /* margin-right: 5%; */
}

.el-1 {
  margin-bottom: 2%;
}

.main-div {
  position: absolute;
}

.rightone {
  position: relative;
  text-align: center;
}

.image-wrapper .right-img {
  width: auto;
  height: 349px;
  object-fit: contain;
  margin: 0 0 0 11rem;
  border-radius: 10px;
}

.gift-content-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 25.2491px 16.8327px;
  gap: 12.62px;
  position: absolute;
  width: 274px;
  height: 89px;
  top: 102px;
  background: #ffffff;
  opacity: 0.95;
  box-shadow: 0px 3.94425px 7.8885px rgb(0 0 0 / 16%);
  backdrop-filter: blur(3.94425px);
  border-radius: 14.7909px;
}

.dashboard-image-wrapper {
  position: absolute;
  width: 302px;
  height: 170px;
  top: 265px;
  filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.16));
  border-radius: 16px;
}

.container-wrapper {
  text-align: center;
}

.second-p {
  margin: 10px auto;
}

/* jumping */

.leftimage-wrapper {
  position: relative;
}
.leftimage-wrapper .jump-image {
  z-index: 9;
}
.leftimage-wrapper .background-img-wrap {
  position: absolute;
  top: 22%;
  left: 10%;
  z-index: 1;
  height: auto;
}
.background-img-wrap img {
  max-width: 500px;
  width: 100%;
}
.esclipse-img-wrap {
  margin-top: 102px;
  text-align: center;
}
.esclipse-img-wrap img {
  max-width: 358px;
  width: 100%;
  height: 40px;
}
.right-content-bullet {
  padding: 80px;
}
.heading-text-wrap,
.heading-wrap {
  font-family: "Outfit", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  color: #464444;
}
.text-container-wrap {
  margin-top: 48px;
  font-family: "Outfit", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 21px;
  line-height: 150%;
  letter-spacing: -0.011em;
  color: rgba(0, 0, 0, 0.8);
}
.points-content-wrap {
  margin: 15px 0;
}
.numbering {
  width: 30px;
  height: 30px;
  background: #24bcb7;
  border-radius: 30px;
  text-align: center;
  color: #fff;
  min-width: 30px;
  min-height: 30px;
}
.point-text-wrap {
  margin-left: 12px;
  max-width: 355px;
  font-family: "Outfit", sans-serif;
}
.start-text-wrap {
  font-family: "Outfit", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 150%;
  letter-spacing: -0.011em;
  color: rgba(0, 0, 0, 0.8);
  margin-top: 38px;
  margin-bottom: 10px;
}
.btn-signup {
  min-width: 151px;
  height: 55px;
  border-radius: 15px;
  margin-top: 14px;
  font-family: "Mulish", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
  text-align: center;
  color: #ffffff;
  background: #359cd7;
  box-shadow: 0px 2px 1px rgb(0 0 0 / 12%);
  border: 1px solid #359cd7;
}
.btn-signup:hover {
  color: #ffffff;
  background: #359cd7;
  box-shadow: 0px 2px 1px rgb(0 0 0 / 12%);
  border: 1px solid #359cd7;
}
.feature-content-wrap {
  box-sizing: border-box;
  max-width: 564px;
  width: 100%;
  height: 121px;
  background: #ffffff;
  border: 1px solid #ecf0ff;
  border-radius: 16px;
  margin-bottom: 23px;
  align-items: center;
}
.feature-description-wrapper {
  font-family: "Outfit", sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: -0.011em;
  color: rgba(0, 0, 0, 0.8);
}
.image-with-feature .left-content-wrap {
  margin-bottom: 20px;
}
.feature-img {
  width: 20%;
  text-align: center;
}
.feature-text {
  width: 80%;
  max-width: 351px;
  font-weight: 500;
  font-size: 21px;
  line-height: 150%;
  letter-spacing: -0.011em;
  color: rgba(0, 0, 0, 0.8);
  font-family: "Outfit", sans-serif;
}
.feature-img img {
  width: 50px;
}

/* card component */
.card-comp div {
  min-height: 311px;
  border-radius: 16px;
}
.card-comp img.img-fluid.mb-3 {
  height: 120px;
  object-fit: contain;
}
.card-comp h5 {
  max-width: 335px;
  margin-left: auto;
  margin-right: auto;
}
.leftone h1 {
  font-family: "Outfit", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 50px;
  color: #464444;
  margin-bottom: 26px;
}
.home-para P {
  font-family: "Outfit", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 23px;
  color: #000000;
  margin-bottom: 31px;
}
.el-1 h3 {
  font-family: "Outfit", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 150%;
  letter-spacing: -0.011em;
  color: rgba(0, 0, 0, 0.8);
}
.second-p h2 {
  max-width: 566px;
  font-family: "Outfit", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  text-align: center;
  color: #464444;
  margin: 0 auto 13px;
}
.second-p p {
  font-family: "Outfit", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  letter-spacing: -0.011em;
  color: rgba(0, 0, 0, 0.8);
  margin-bottom: 32px;
}

.image-wrapper {
  position: relative;
}
.image-wrapper:before {
  content: "";
  background: linear-gradient(180deg, #3e8bd0 0%, #3db0a8 100%);
  opacity: 0.3;
  filter: blur(300px);
  transform: rotate(-90deg);
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
  top: 0;
  right: -52px;
}
.first-p .row {
  align-items: center;
}

.image-wrapper img {
  width: 100%;
  height: auto;
}
.left-content-wrap {
  margin-bottom: 20px;
}

.austin-wrapper {
  position: relative;
  text-align: center;
  max-width: 500px;
  margin: 0 auto;
}

.point-icon {
  position: absolute;
  bottom: -80px;
  left: 20px;
}
.frame-image-wrapper {
  position: absolute;
  right: 36px;
  top: -40px;
}
form.sign-up-wrap input.form-control,
.login-wrapper input,
.features.basic-info-wrapper input.form-control,
.admin-login-container input,
.store-signup-wrap input {
  background: #ffffff;
  border: 1px solid #ededed;
  border-radius: 15px;
  height: 55px;
  font-family: "Mulish", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #666161;
}
.btn.btn-primary.send-otp-btn {
  min-width: 100%;
}
.basic-info-wrapper label strong,
.social.media.wrapper p {
  text-align: left;
  font-weight: 700;
  font-family: "Mulish", sans-serif;
}
div.react-tel-input .flag-dropdown,
.react-tel-input .flag-dropdown.open {
  border-radius: 15px 0 0 15px !important;
  background-color: transparent !important;
}
.react-tel-input .selected-flag:hover,
.react-tel-input .selected-flag:focus {
  background-color: transparent !important;
}
.opt-for-text-wrapper .information-text-sms p {
  margin: 0 0 0 10px;
}
/* .opt-for-text-wrapper .checkbox, .opt-for-text-wrapper .checkbox input[type="checkbox"] {
    width: 18px;
    height: 18px;
} */
input#checkbox {
  width: 18px;
  height: 15px;
  margin-right: 5px;
  margin-top: 4px;
}
.opt-for-text-wrapper {
  align-items: center;
  margin-bottom: 20px;
}
.social.media.wrapper p {
  text-align: left;
}
textarea.form-control {
  background: #ffffff;
  border: 1px solid #ededed;
  border-radius: 15px;
  font-family: "Mulish", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #666161;
}
.about-me-wrapper {
  margin-top: 0.5rem;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}
/* .invalid-feedback {
    font-size: 13px;
    font-family: mulish;
    FONT-WEIGHT: 700;
    line-height: 18px;
} */

form.sign-up-wrap button,
.login-wrapper button {
  font-family: "Mulish", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
}
.admin-login-container input.btn.btn-primary.btn-block {
  background: #359cd7;
  border-radius: 15px;
  min-width: 150px;
  min-height: 55px;
  font-family: "Mulish", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
  text-align: center;
  padding: 15px 10px;
  border: none;
  color: #fff;
  width: 100%;
}
.login-wrapper button {
  width: 100%;
}
form.sign-up-wrap button {
  max-width: 224px;
  width: 100%;
  margin-top: 20px;
}

.login-wrapper p.alternative {
  font-family: "Mulish", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  padding-top: 10px;
}
.admin-login-container p {
  font-family: "Mulish", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
}
.signup-wrapper p.paragraph {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  text-align: left;
  line-height: 22px;
  margin-bottom: 30px;
}
.signup-header {
  margin-bottom: 10px;
}
img.austin-img {
  border-radius: 14px;
}
.social.media.wrapper {
  margin-top: 12px;
}
a.dropdown-item {
  font-size: 14px;
  font-family: "Mulish", sans-serif;
  font-weight: 700;
  line-height: 19px;
  white-space: unset;
}
.bell-icon.show.dropdown .notification-menu-box.dropdown-menu.show {
  /* min-width: 290px; */
  width: 30rem;
  right: 0 !important;
  left: unset !important;
}
a.dropdown-item:not(:last-child) {
  border-bottom: 1px solid #e8e8e8;
}
.dropdown-menu.show {
  border: none;
  box-shadow: 4px 5px 20px #0006;
}
.requests-wrapper table.table {
  margin-bottom: 0;
}
.requests-wrapper table.table td {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  font-feature-settings: "salt" on;
  color: #374151;
  vertical-align: middle;
}
.requests-wrapper table.table tbody tr td {
  /* padding-top: 24px;
    padding-bottom: 24px; */
}
.requests-wrapper td p {
  margin-bottom: 0;
  text-align: left;
}
.requests-wrapper button.btn.button-view-host {
  background: #359cd7;
  border-radius: 8px;
  font-family: "Mulish", sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 24px;
  box-shadow: none;
}
.requests-wrapper button.btn.button-view-host-status {
  font-family: "Mulish", sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 24px;
  width: 100%;
}
.review-wrapper .list-item-custom.list-group-item {
  font-family: "Mulish", sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 24px;
  width: 100%;
}
.modal.show .modal-dialog ::-webkit-scrollbar {
  width: 8px;
  height: 4px;
}

/* Track */
.modal.show .modal-dialog ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #ededed;
  border-radius: 4px;
}

/* Handle */
.modal.show .modal-dialog ::-webkit-scrollbar-thumb {
  background: #d7d7d7;
  border-radius: 4px;
}
.requests-wrapper td span {
  vertical-align: middle;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}
.requests-wrapper td p {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
}
.requests-wrapper table.table tbody tr td img.host-pp-small {
  margin-right: 12px;
}
.requests-wrapper {
  padding-top: 20px;
}
.features.host-list-wrapper,
.features.host-request-wrapper {
  padding: 5px 0;
}
.requests-wrapper table.table tbody tr td:first-child,
.requests-wrapper table.table tbody tr td:last-child {
  padding: 24px;
}
.requests-wrapper table.table tbody tr:last-child {
  border: none;
  border-color: transparent;
}
.requests-wrapper table.table thead tr th:first-child,
.requests-wrapper table.table thead tr th:last-child {
  padding-left: 24px;
  padding-right: 24px;
}
.requests-wrapper table.table tr td p strong {
  font-family: "Mulish", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 13.1415px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: #181818;
}
.requests-wrapper table.table th {
  font-family: "Mulish", sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: 1.31484px;
  text-transform: capitalize;
  color: #374151;
}
.requests-wrapper table.table .host-pp-small {
  height: 41px;
  width: 41px;
  object-fit: cover;
}
.requests-wrapper table.table tbody {
  border-top: 0.821343px solid #ededed;
}
.requests-wrapper table.table .table-responsive-lg {
  height: calc(100vh - 190px);
  overflow-y: auto;
  padding-right: 16px;
}

/* Meeting room */
input#publisher-name {
  background: #ffffff;
  border: 1px solid #e5e5e5;
  box-shadow: 0px 1px 2px rgb(16 24 40 / 5%);
  border-radius: 6px;
  height: 55px;
  font-family: open sans;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #000;
}
.details-content-wrapper .details-wrap {
  align-items: center;
  justify-content: center;
  height: 100%;
}
.details-content-wrapper .details-wrap button.btn.btn-primary.btn-block {
  width: 100%;
  background: #359cd7;
  border-radius: 8px;
  font-family: "Mulish", sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 24px;
}
.details-content-wrapper .details-wrap h3 {
  font-family: "Mulish", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 45px;
}
.waiting-video-controller-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
}
.waiting-video-controller-wrap > div {
  margin: 0 !important;
  padding: 0 10px;
}
.waiting-video-controller-wrap input {
  margin: 0 !important;
}
.waiting-room-wrapper.container {
  height: 100%;
  padding: 20px 0 40px;
}
.waiting-video-controller-wrap .form-check-input[type="checkbox"] {
  opacity: 0;
}
.waiting-video-controller-wrap label {
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
}
.waiting-video-controller-wrap label:after {
  content: "";
  border-left: 2px solid #212529;
  position: absolute;
  left: -3px;
  transform: rotateY(-5deg);
  width: 112px;
  height: 30px;
  -webkit-transform: translateY(-20px) translateX(5px) rotate(27deg);
  top: 42px;
}
.waiting-video-controller-wrap label.microphone-active:after,
.waiting-video-controller-wrap label.camera-active:after {
  border-left: none;
  position: unset;
  display: none;
}

.waiting-video-controller-wrap label svg {
  padding-right: 5px;
}
.waiting-video-controller-wrap svg {
  width: 24px;
  height: 24px;
}
.points-content-wrap {
  text-align: left;
}
.heading-text-wrap {
  font-size: 25px;
  text-align: left;
  font-weight: bold;
}
.points-content-wrap {
  text-align: left;
  font-size: 18px;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
}
.container.signup-wrapper,
.container.login-wrapper,
.container.waiting-room-wrapper.container {
  min-height: calc(100vh - 275px);
}
.feature-description-wrapper {
  margin-top: 12px;
}
.container.waiting-room-wrapper.container .waiting-contents {
  padding: 0 12px;
}
@media only screen and (max-width: 767px) {
  .image-wrapper:before {
    right: 0;
  }
  .container.waiting-room-wrapper .details-content-wrapper {
    padding-top: 25px;
  }
  .about-me-wrapper {
    margin-bottom: 20px;
  }
  .details-content-wrapper .details-wrap h3 {
    font-size: 21px;
    line-height: 32px;
  }
  .card-components-wrap .card-comp {
    margin-bottom: 2rem !important;
  }
  .esclipse-img-wrap {
    margin-top: 30px;
  }
  .leftone h1,
  .second-p h2,
  .heading-text-wrap,
  .heading-wrap {
    font-size: 24px;
    line-height: 34px;
  }
  .card-components-wrap .card-comp h5 {
    font-size: 16px;
    line-height: 22px;
  }
  .feature-text {
    font-size: 16px;
    line-height: 22px;
    padding-right: 12px;
  }
  .point-text-wrap {
    font-size: 16px;
    line-height: 22px;
  }
}
@media only screen and (min-width: 768px) {
  .signup-wrapper {
    padding: 0 0 50px 0;
  }
  .login-wrapper h1 {
    font-family: "Mulish", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 62px;
    line-height: 78px;
    max-width: 414px;
  }
  .login-wrapper .right-text p.paragraph {
    font-family: "Mulish", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    color: #000000;
    text-align: left;
  }
  .login-column-wrap,
  .signup-item-wrap {
    align-items: center;
  }
  nav.navbar .login-header {
    font-family: "Mulish";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    padding: 0 20px;
  }

  .login-wrapper p.alternative {
    padding-top: 40px;
  }
}
@media only screen and (max-width: 1400px) {
  img.login-img {
    width: 100%;
    height: auto;
  }
}

@media only screen and (max-width: 768px) {
  /* .requests-wrapper td span {
    font-size: 12px;
    font-family: mulish;
    line-height: 16px;
    FONT-WEIGHT: 700;
}
.requests-wrapper table.table tbody tr td:first-child, .requests-wrapper table.table tbody tr td:last-child {
    padding: 10px;
}
.requests-wrapper table.table thead tr th:first-child, .requests-wrapper table.table thead tr th:last-child {
    padding-left: 10px;
    padding-right: 10px;
}
table.table th {
    font-size: 12px;
    FONT-WEIGHT: 500;
    padding: 10px 0;
}
.requests-wrapper td p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
} */
  .monthly-data-wrapper {
    margin-bottom: 16px;
  }
}
@media only screen and (max-width: 600px) {
  .bell-icon.show.dropdown .notification-menu-box.dropdown-menu.show {
    width: 20rem;
  }
}
@media only screen and (max-width: 400px) {
  .bell-icon.show.dropdown .notification-menu-box.dropdown-menu.show {
    width: 15rem;
  }
}
.notification-menu-box ul.notification-nav {
  display: flex;
  width: 100%;
  padding: 10px 0 0;
}
p.policy-statement {
  margin: 0;
  color: #939393;
  font-size: 12px;
  font-weight: 500;
}

p.policy-statement span {
  cursor: pointer;
  text-decoration: underline;
}

p.policy-statement span:hover {
  text-decoration: none;
}

p.assistance-text span {
  font-size: 16px;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  cursor: pointer;
}
p.assistance-text span:hover {
  text-decoration: underline;
}

p.assistance-text {
  font-size: 16px;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
}

img.venmologo-landing-page {
  margin-left: 10px;
}
form.sign-up-wrap button.btn.btn-primary.btn-block {
  margin-top: 0;
}
.form-control.is-invalid,
.was-validated .form-control:invalid {
  background-image: none !important;
}
.image-wrapper-welcome {
  text-align: right;
}
.text-container-wrap.two_wrap_demo {
  margin-top: 5px;
}
