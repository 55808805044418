.commission-filter {
    background: white;
    display: flex;
    gap: 30px;
    border-radius: 10px;
    padding: 10px 0;
  }
  
  .predefined-commission .ant-col {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .filter-modal {
    width: 800px !important;
  }
  
  .filter-modal .ant-modal-body {
    height: 400px;
  }
  
  .custom-commission .ant-picker-date-panel {
    width: 240px;
  }
  
  .custom-commission .ant-picker-range {
    min-width: 480px;
  }
  
  @media (max-width: 760px) {
    .custom-commission .ant-picker-date-panel {
      width: 230px;
    }
  
    .custom-commission .ant-picker-range {
      min-width: 400px;
    }
  }
  
  @media (max-width: 760px) {
    .custom-commission .ant-picker-date-panel {
      width: 230px;
    }
  
    .custom-commission .ant-picker-range {
      min-width: 400px;
    }
  }
  
  @media (max-width: 665px) {
    .commission-filter {
      flex-direction: column;
    }
  }
  
  @media (max-width: 490px) {
    .custom-commission .ant-picker-date-panel {
      width: 250px;
    }
  
    .custom-commission .ant-picker-range {
      min-width: 250px;
    }
  
    .custom-commission .ant-picker-panels > *:first-child button.ant-picker-header-next-btn {
      visibility: visible;
    }
  
    .custom-commission .ant-picker-panels > *:first-child button.ant-picker-header-super-next-btn {
      visibility: visible;
    }
  
    .custom-commission .ant-picker-panels > *:last-child {
      display: none;
    }
  
    .custom-commission .ant-picker-footer-extra > div {
      flex-wrap: wrap;
    }
  }
  