.image-listing {
  height: 167px;
  object-fit: cover;
  width: 100%;
}
.image-zoom-custom {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
  cursor: pointer;
}
.image-zoom-custom:hover {
  -webkit-transform: scale(1);
  transform: scale(1);
}
.cardcustom {
  overflow: hidden;
}
.detail-view-text {
  color: #359cd7;
  cursor: pointer;
}

.cardcustom {
  height: 100%;
  border-radius: 7px;
  /* width: 280px; */
}

.accepted-wrapper {
  background: #359cd7;
  border-radius: 30px;
  text-align: center;
  color: #fff;
}

.rejected-wrapper {
  background: #ef4444;
  border-radius: 30px;
  text-align: center;
  color: #fff;
}

.pending-wrapper {
  background: #fcd34d;
  border-radius: 30px;
  text-align: center;
  color: #fff;
}

.action-button-product {
  background: transparent;
  margin: 10px 0;
  border: none;
  color: red;
}
.card-title {
  margin-bottom: 5px;
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
}
.product-col p.card-text {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: #737373;
  margin-bottom: 20px;
}
.pricing-wrapper .card-title {
  margin-bottom: 12px;
}
.pending-wrapper,
.action-button-product,
.rejected-wrapper,
.accepted-wrapper {
  padding: 2px 10px;
  font-family: "Mulish";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  text-transform: capitalize;
}
.product-tag {
  max-width: max-content;
  margin-left: auto;
}
.footer-button-product .btn-outline-warning {
  color: #ef4444;
  border-color: #ef4444;
  font-family: "Mulish";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  min-width: 95px;
}
.footer-button-product .btn-outline-warning:hover {
  color: #fff;
  background-color: #ef4444;
}
.footer-button-product .btn-outline-primary {
  color: #359cd7;
  border-color: #359cd7;
  font-family: "Mulish";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  min-width: 95px;
}
.footer-button-product .btn-outline-primary:hover {
  color: #fff;
}
.product-description-wrapper p.card-text {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: #737373;
}
.navigation-product-menu {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
button.btn.btn-primary.add-product {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: #ffffff;
  padding: 10px;
}
.navigation-product-menu h5.text-start {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 30px;
}
.search-wrapper input.form-control {
  padding: 8px 12px;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  box-shadow: 0px 1px 2px rgb(16 24 40 / 5%);
  border-radius: 6px;
}
.navigation-product-menu ul.product-nav li.nav-link {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 170.23%;
  color: #5c5c5c;
}
ul.product-nav li.nav-link.active-selection {
  color: #359cd7 !important;
  text-decoration-color: #359cd7;
  text-underline-position: under;
}
ul.product-nav.d-flex {
  padding-left: 0;
  margin-bottom: 0;
}
p.detail-view-text.card-text {
  margin-bottom: 10px;
  font-size: 15px;
  text-decoration: underline;
  text-underline-position: under;
}
.product-detail .active.card {
  border-color: #329dd775;
  outline: 0;
  box-shadow: 0 0 0 0.25rem #329dd775;
}
.range-slider__wrap {
  max-width: 500px;
  width: 100%;
}
.slider-wrapper {
  align-items: center;
  margin-bottom: 30px;
}
.slider-wrapper p.value-box.slider-value {
  margin-bottom: 0;
}
.card-img-top.venmo-logo-card {
  max-height: 230px;
  width: 250px;
}

div.venmo-form-wrapper {
  border-radius: 12px;
  box-shadow: 0px 5px 8px rgb(0 0 0 / 30%);
  padding: 20px;
}
.list-item-rating-dashboard .small-image-list {
  width: 60px;
  height: 60px;
}
