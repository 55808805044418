.column-wrapper {
  background: #ffffff;
  border: 1.07993px solid #f2eee9;
  border-radius: 16.8771px;
  text-align: center;
}

.showroom-data {
  flex-direction: column;
  padding: 20px;
}

ul.chart-nav {
  display: flex;
  flex-wrap: wrap;
  cursor: pointer;
  padding-left: 0;
}

li.nav-link:active {
  /* text-decoration: underline; */
  color: #359cd7;
}

.host-dashboard-chart {
  height: 100px;
  width: 100px;
}
@media only screen and (max-width: 1450px) {
  ul.chart-nav li.nav-link {
    padding: 0.5rem;
  }
}

div.features.payout-list-for-store {
  display: block;
  padding: 20px;
  height: 100%;
}
div.features.d-block.store-liable-payment-wrapper {
  padding: 20px;
  height: auto;
}
.braintree-sheet__content--form
  .braintree-form__field-group
  .braintree-form__field
  .braintree-form__hosted-field {
  height: 50px;
  background: #f8f8f8;
  border-radius: 8px;
  width: 100%;
}
.braintree-option .braintree-option__label {
  text-align: left;
}
.braintree-checkout-wrapper label {
  width: 100%;
}
.braintree-option:hover {
  background: #349cd7;
}
.braintree-option:hover .braintree-option__label {
  color: #fff;
}
.features.store-admin-dashboard-wrap {
  padding: 0;
}
.features.store-admin-dashboard-wrap th,
.features.store-admin-dashboard-wrap td {
  padding: 15px 26px;
  vertical-align: middle;
}
.features.store-admin-dashboard-wrap td {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
}

.schedule-demo-graph{
  width: 100%;
}
