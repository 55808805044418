/* Download button styles */
.download-data-button {
  text-align: right;
}

.download-data-btn {
  min-width: 76px;
  min-height: 43px;
  margin: 0 5px;
  background-color: #359cd7;
  color: #fff;
}

.download-data-btn:not(:disabled):not(.ant-btn-disabled):hover {
  background-color: #359cd7;
  color: #fff;
}

/* Styling for filter bar */
.filter-bar {
  padding: 15px;
  border-radius: 8px;
  display: flex;
  flex-wrap: wrap;
}

.filter-bar select {
  font-size: 14px;
  padding: 10px;
  border-radius: 4px;
}

.filter-bar label {
  font-weight: 600;
}

.filter-bar .col {
  flex: 1 1 45%;
  margin: 5px 0;
}

@media (max-width: 768px) {
  .filter-bar .col {
    flex: 1 1 100%;
  }
}

.summary-section {
  display: flex;
  justify-content: space-between;
  margin: 20px 0px;
  margin-top: 10px;
  flex-wrap: wrap;
  padding: 10px;
  gap: 10px; /* Adds consistent spacing */
}

.summary-card {
  background-color: white;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
  border-radius: 15px;
  padding: 20px;
  flex: 1;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 250px; /* Prevent cards from becoming too small */
}

.summary-card h5 {
  color: #558a6a;
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 10px;
}

.summary-card h2, .summary-card h3 {
  color: #293a75;
  font-weight: bold;
}

.summary-card h2 {
  font-size: 2.5rem;
}

.summary-card h3 {
  font-size: 1.5rem;
}

.summary-card p {
  color: #9aaf95;
  font-size: 0.875rem;
  margin-top: 5px;
}

/* Media Query for Mobile */
@media (max-width: 768px) {
  .summary-section {
    flex-direction: column; /* Stacks the cards vertically */
    align-items: center; /* Centers the cards */
  }

  .summary-card {
    width: 100%; /* Full width for mobile screens */
    max-width: 400px; /* Optional: Limit max width for readability */
    margin-bottom: 10px; /* Adds spacing between stacked cards */
  }
}


/* Table container with overflow support */
.table-container {
  width: 100%;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
}

.table-wrapper {
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  width: 100%;
}

.table-payout {
  width: 100%;
  table-layout: auto;
  background-color: #ffffff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  border-collapse: collapse;
}

.table-payout th, .table-payout td {
  padding: 12px;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.table-payout th {
  background-color: #f4f6f8;
  color: #3c886b;
  font-weight: 600;
}

.table-payout td {
  color: #23265f;
}

/* Ensure enough width on larger screens */
@media (min-width: 1024px) {
  .table-payout {
    min-width: 1200px;
  }
}

.table-product p {
  width: fit-content;
  text-wrap: wrap;
}

.table-result {
  width: 12vw;
}

.no-data-wrap {
  text-align: center;
  padding: 20px;
  font-size: 16px;
  color: #999;
}

/* Buttons inside table */
.button-wrapper-product .btn {
  margin: 0 10px;
  padding: 10px 20px;
  border-radius: 6px;
}

.download-data-btn {
  background-color: #0069d9;
  color: #ffffff;
  padding: 10px 20px;
  border-radius: 6px;
  margin-top: 20px;
}

.download-data-btn:hover {
  background-color: #0056b3;
  color: #ffffff;
}

/* Responsive adjustments for smaller screens */
@media (max-width: 1024px) {
  .table-payout {
    min-width: unset;
  }
}

@media (max-width: 800px) {
  .table-payout {
    width: 800px;
    overflow-x: auto;
  }
  .features.payout-tables table.table th,
  .features.payout-tables table.table td {
    padding: 15px 10px;
  }
  .features.payout-tables th.product-head {
    width: 60px;
  }
}

@media (max-width: 768px) {
  .table-payout th, .table-payout td {
    padding: 10px;
    font-size: 12px;
    max-width: 100px;
  }
}

@media (max-width: 480px) {
  .table-payout th, .table-payout td {
    padding: 8px;
    font-size: 11px;
    max-width: 80px;
  }
}

.payout-image--title-wrapper {
  align-items: center;
}

th.product-head {
  width: 30%;
}
th.date-head {
  width: 15%;
}
th.payout-head {
  width: 20%;
}
th.amount-head {
  width: 20%;
}
th.status-head {
  width: 20%;
}

.paid-text {
  color: rgba(62, 139, 208, 1);
}

.date-payout {
  font-family: "Mulish", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 11.5px;
  line-height: 15px;
  color: #5c5c5c;
}

table.table-payout.table tr.payout-table-items td p strong {
  font-family: "Mulish", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: #181818;
}

.features.payout-tables {
  padding: 0;
}

.payout-page-title-wrapper p {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #585757;
  margin-bottom: 20px;
}

.payout-data-section {
  padding: 20px;
  border-radius: 10px;
  background-color: #ffff;
}

.features.payout-tables table.table th,
.features.payout-tables table.table td {
  padding: 15px 26px;
}

table.table-payout.table th:last-child {
  max-width: 95px;
}

table.table-payout.table th.receive-head.price-head {
  width: 150px;
}

tr.payout-table-items .host-pp-small {
  height: 41px;
  width: 41px;
  border-radius: 6px;
  object-fit: cover;
}

.image-wrap {
  max-width: 68px;
}

table.table-payout.table th {
  font-family: "Mulish", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: #181818;
  text-transform: capitalize;
  padding-bottom: 16px;
}

.features.payout-tables tbody {
}

.features.payout-tables .table-responsive-lg {
  overflow-y: auto;
}

.features.payout-tables table.table th {
  font-family: "Mulish", sans-serif;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 1.3px;
  text-transform: capitalize;
  color: #000;
  font-size: 16px;
}

.features.payout-tables table.table td {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #374151;
  vertical-align: middle;
}

.features.payout-tables table.table tbody tr td {
  padding-top: 24px;
  padding-bottom: 24px;
}

.features.payout-tables td p {
  margin-bottom: 0;
  text-align: left;
}

.payout-page-note-section {
  font-size: 14px;
  background-color: #ffff;
  padding: 20px;
  border-radius: 10px;
}
