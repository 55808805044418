.host-dashboard-chart {
}
.features.chart-wrap {
  flex-direction: column;
  padding: 20px;
}
ul.chart-nav {
  display: flex;
  flex-wrap: wrap;
  cursor: pointer;
}
.monthly-data-wrapper nav.nav {
  align-items: baseline;
  justify-content: space-between;
  width: 100%;
}
.monthly-data-wrapper .tab-nav-list {
  width: 100%;
}
