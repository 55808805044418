.row-meeting-wrapper {
  /* width: 96rem; */
  /* padding: 0 20px; */
}

.demo-request-wrapper {
  padding: 10px 20px 10px 20px;
  align-items: unset;
  display: block;
  height: 100vh;
  overflow-y: auto;
}
.request-title-wrapper {
  width: 100%;
}
.request-component {
  background: #ffffff;
  box-shadow: 0px 3.88713px 7.77427px rgba(0, 0, 0, 0.08);
  border-radius: 6.0401px;
  margin-bottom: 30px;
  padding: 20px;
}
/* .time-detail-wrapper .timeit-selected-1-1-9 {
    color: #5C5C5C;
} */
:root {
  --timeit-primary-color: #359cd7;
}

/* div.timeit-timeWrapper-0-1-7 {
    font-size: 15px;
    line-height: 30px;
    padding-top: 0px;
} */
path.timeit-control__svg-0-1-4 {
  stroke: #2d5bff;
}
.time-detail-wrapper button.btn.btn-demo-reject.btn-secondary.btn-sm {
  background: transparent;
  color: #359cd7;
  border-color: #359cd7;
}

.time-detail-wrapper button.btn.btn-secondary.btn-sm:disabled {
  background-color: grey;
}

.time-detail-wrapper button.btn.btn-secondary.btn-sm {
  padding: 10px 32px;
  background: #359cd7;
  border-radius: 8px;
  border-color: transparent;
  font-family: "Mulish", sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.02em;
  color: #ffffff;
  width: 100%;
  margin-bottom: 14px;
}
/* div.timeit-wrapper-0-1-5 {
    width: 50px;
    height: 35px;
}
div.timeit-selector-0-1-6 {
    height: 30px;
    top: 0;
} */
.custom-time-wrapper {
  padding: 0 0 20px 0;
}
.time-detail-wrapper select.form-select {
  background: #f8f8f8;
  border-radius: 6.35775px;
  padding: 12.7155px 8px 12.7155px 12.7155px;
  font-family: "Mulish", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  /* color: #B7B3AF; */
  margin-bottom: 20px;
  white-space: normal;
}

.rbc-event,
.rbc-day-slot .rbc-background-event {
  border-radius: 4px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-box-shadow: none;
  box-shadow: none;
  margin: 0;
  padding: 2px 5px;
  background-color: #3174ad;
  cursor: pointer;
  width: 100%;
  text-align: left;
  background: linear-gradient(
      0deg,
      rgba(0, 133, 255, 0.1),
      rgba(0, 133, 255, 0.1)
    ),
    linear-gradient(0deg, #ffffff, #ffffff);
  font-family: "Mulish", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #0085ff;
  max-width: max-content;
}

/* .rbc-btn-group > button:not(:first-child):not(:last-child) {
    display: none;
} */
#popover-trigger-click-close h5 {
  font-family: "Mulish", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: #747474;
}
#popover-trigger-click-close {
  padding: 12px 16px;
  background: #ffffff;
  border-radius: 8px;
  max-width: 240px;
  width: 100%;
  margin: 0 auto;
}
#popover-trigger-click-close p {
  font-family: "Mulish", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.025em;
  text-transform: uppercase;
  margin-bottom: 10px;
}
#popover-trigger-click-close p.time-wrap {
  color: #359cd7;
}
#popover-trigger-click-close button.btn.btn-primary {
  padding: 6px 20px;
  width: 100%;
  background: #359cd7;
  border-radius: 8px;
  min-height: auto;
  font-family: "Mulish", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 12px;
}
#popover-trigger-click-close button.btn.btn-primary.btn-sm-primary.btn-failed {
  background: #dd3333;
}
.button-wrapper button {
  font-family: "Mulish", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 9.85612px;
  line-height: 13px;
  background: transparent;
  border: transparent;
  color: #23265f;
}

.features.meeting-wrapper .rbc-row-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  overflow-y: auto;
}
.features.meeting-wrapper .rbc-row-content .rbc-event {
  margin: 0 5px;
  width: calc(100% - 10px);
}
.features.meeting-wrapper .rbc-row-content .rbc-date-cell {
  padding: 5px;
  text-align: left;
  font-family: "Mulish", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #969696;
}
.features.meeting-wrapper .rbc-row-content .rbc-event-content {
  font-family: "Mulish", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #0085ff;
}
.rbc-month-header .rbc-header {
  font-family: "Mulish", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 15px;
  color: #969696;
  text-transform: uppercase;
  text-align: left;
  padding: 7px;
}
.features.demo-request-wrapper h5 {
  font-family: "Mulish", sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 30px;
  color: #181818;
}
.button-wrapper {
  justify-content: center;
}
#popover-trigger-click-close p.user-name {
  margin-bottom: 4px;
  color: #359cd7;
}
.meeting-note-message {
  cursor: pointer;
  color: #0085ff;
  width: 20%;
}
.rbc-event-content {
  color: #359cd7;
}

.rbc-calendar {
  z-index: 0;
}
.rbc-ellipsis,
.rbc-show-more,
.rbc-row-segment .rbc-event-content,
.rbc-event-label {
  white-space: unset;
  word-break: break-word;
}
span.rbc-toolbar-label {
  max-width: 200px;
}
div.rbc-toolbar {
  justify-content: space-between;
}
.rbc-day-slot .rbc-event-label {
  color: #3174ad;
}
@media only screen and (max-width: 768) {
  .meeting-wrapper {
    margin-bottom: 15px;
  }
  div.row-meeting-wrapper {
    padding: 0;
  }
}

div.note-display-wrapper {
  height: 80px;
  overflow-y: auto;
  margin: 10px 0;
  border: 0.5px solid #ced4da;
  border-radius: 10px;
  padding: 5px;
  font-size: 12px;
  text-transform: none;
}

.subscript-para {
  height: 7px;
}
@media only screen and (max-width: 991px) {
  .demo-request-wrapper {
    margin-top: 20px;
  }
}

p.small-label {
  margin: 0;
}

a.add_to_calendar_wrapper {
  text-decoration: none;
  background: #359cd7;
  color: #fff;
  border-radius: 10px;
  padding: 5px;
  width: 100%;
  display: block;
  text-align: center;
}

div.expired_meeting_date {
  color: #dd3333;
  text-align: center;
}

p.detail_desc_name {
  margin-bottom: 0.2rem;
}

p.schedule_time_desc {
  font-weight: 200;
  color: #525151;
  font-size: 14px;
}
span.letter_title_desc {
  font-weight: 600;
}
.request_wrapper_custom {
  max-height: 50vh;
  overflow-y: auto;
}
.scheduled_wrapper {
  height: auto;
}

.time-detail-wrapper .ant-select {
  width: 100%;
  margin-bottom: 20px;
  min-height: 48px;
}

.ant-select-dropdown .ant-select-item-option-content {
  white-space: normal;
}

.revised-schedule select {
  padding: 10px;
}

.link-button {
  background: none;
  color: blue;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  text-decoration: underline;
}

.link-button:hover {
  color: darkblue;
}
