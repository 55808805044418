.host-pp-small {
  height: 42px;
  width: 42px;
  border-radius: 50%;
  margin-right: 10px;
  object-fit: cover;
}
.button-view-host {
  background: #359cd7;
  box-shadow: 0px 2px 1px rgb(0 0 0 / 12%);
  border-radius: 15px;
  min-width: 100px;
  min-height: 30px;
  font-family: "Mulish", sans-serif;
  color: #fff;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  border: none;
}

.button-view-host-status {
  background: #ffffff;
  min-width: 100px;
  min-height: 30px;
  font-family: "Mulish", sans-serif;
  color: #359cd7;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  text-align: left;
  border: none;
  padding: 0;
}

.button-view-host:hover {
  color: #fff;
  background-color: #0b5ed7;
  border-color: #0a58ca;
}

.list-item-custom {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  color: #111827;
}

.arrows {
  display: inline-flex;
  align-items: center;
}
.arrow-grid {
  display: grid;
  width: auto;
  margin-left: 8px;
}

.uploaded-media-icon {
  height: 25px;
  width: 25px;
  cursor: pointer;
}

.table-store {
  align-items: center;
  padding: 52px 20px 64px 20px;
  height: 100%;
  background: #ffffff;
  border-radius: 18px;
}

.image-title-wrapper {
  cursor: pointer;
}

.custom-carousel.carousel-item {
  /* height: 750px; */
  text-align: center;
}

video.video-player_product {
  height: 100%;
  width: 100%;
}
.add_media {
  margin: 20px 0;
  cursor: pointer;
}

.custom-carousel.carousel-item img {
  height: auto;
  margin: 0 auto;
  max-height: calc(100vh - 170px);
  object-fit: contain;
}
.modal.show .modal-dialog {
  height: auto;
  margin-top: 0;
  margin-bottom: 0;
}
/* .modal.show .modal-dialog .modal-footer {
    display: none;
} */
.carousel-control-next,
.carousel-control-prev {
  padding: 10px;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background-color: #000;
}
.carousel-dark .carousel-control-next-icon,
.carousel-dark .carousel-control-prev-icon {
  filter: none;
}
.carousel-inner {
  max-width: calc(100% - 100px);
  margin: 0 auto;
  padding: 0 10px;
}
.carousel .carousel-control-next {
  right: -8px;
}
