p {
  font-family: "Outfit";
  font-style: normal;
  /* font-weight: 500; */
  /* font-size: 16px; */
  /* line-height: 150%; */
  text-align: unset;
  /* letter-spacing: -0.011em; */
  /* color: rgba(0, 0, 0, 0.8); */
}

li {
  font-family: "Outfit";
  font-style: normal;
  text-align: justify;
}
.tc-content {
  scroll-behavior: smooth;
  max-height: calc(100vh - 260px);
  overflow-y: auto;
  padding-right: 20px;
}
.button-place {
  padding: 3% 0 0 0;
}

button.btn.btn-primary.button-form {
  margin: 0 0 2px 11px;
}

.t-c-acceptor {
  display: block;
}

.shopify-privacy-policy {
  padding: 0 80px;
}

.privacy-info {
  font-family: Arial, sans-serif;
  font-size: 15px;
}

.privacy-margin {
  margin-bottom: 20px;
}

.privacy-notice-header {
  font-size: xx-large;
  font-weight: lighter;
}

.privacy-notice-box {
  margin-bottom: 25px;
}

.privacy-last-updated {
  font-weight: bold;
}


@media screen and (max-width: 480px) {
  .shopify-privacy-policy {
    padding: 0 15px;
  }
}
