body {
  background-color: #e5e5e5;
}

/* .img-laptop{
    position: absolute;
    width: 429.04px;
    height: 344.86px;
    left: 514.03px;
    top: 116px;
} */

.main-wrapper {
  padding: 15px;
}

.box {
  background-color: var(--box-bg);
  padding: 30px;
  border-radius: 20px;
  box-shadow: var(--box-shadow);
}
