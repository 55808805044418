.submit-button {
  padding: 3% 0;
}

img.loyalty-card {
  max-width: 290px;
  height: auto;
  border-radius: 12px;
  margin-right: auto;
  margin-left: 0;
}
.left-form-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.skip-button-wrapper a.btn.btn-outline-primary {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 170.23%;
  min-height: 42px;
  padding: 8px 24px;
  margin-left: 0;
  width: 100%;
  margin-top: 28px;
  max-width: 312px;
}
.skip-button-wrapper {
  width: 100%;
}
.form-select-country,
.form-select-other {
  background-color: #f8f8f8;
  border-radius: 8px;
  min-height: 56px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 170.23%;
}

label.form-label {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #181818;
  margin-bottom: 12px;
}
.features.loyalty-card-form {
  padding: 40px 0;
}
.features.loyalty-card-form .container {
  max-width: 1000px;
  width: 95%;
}
.card-image-wrapper {
  margin-right: auto;
}
