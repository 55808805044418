body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #e5e5e5;
}
body::-webkit-scrollbar {
  display: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#layoutContainerWrapper {
  height: 93vh;
}

#waiting-room-video-container > .OT_publisher {
  padding-bottom: 56.25%;
  width: 100% !important;
  border-radius: 15px;
}

@media (max-width: 500px) {
  p.slider-value {
    padding: 0;
  }
  #publisherContainer {
    top: 13px;
    left: 10px;
  }

  #publisherContainer > .OT_publisher {
    margin: 10px;
    width: 95% !important;
    height: 100% !important;
    border-radius: 10px;
  }

  #layoutContainer {
    height: 90%;
  }

  #layoutContainer > .OT_subscriber {
    border-radius: 10px !important;
    left: -2px !important;
    width: 99% !important;
    height: 100%;
  }
}

button.submit-purebrand-btn {
  line-height: 12px !important;
}
table td {
  vertical-align: middle;
}
table.table-store-payout.table .product-name {
  min-width: 180px;
}
