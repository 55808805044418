.product-lists {
  max-height: 380px;
}

.product-detail {
  max-height: 380px;
}
.list-shopify-products-wrapper,
.product-detail .card {
  background: #ffffff;
  box-shadow: 0px 4px 8px rgb(0 0 0 / 8%);
  border-radius: 12.8359px;
  height: 100%;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.product-detail
  .card
  img.card-img-top.image-listing.image-fluid.image-responsive {
  object-fit: cover;
}

.button-wrapper-product {
  padding-top: 10px;
}

.tool-info {
  text-align: right;
}

.uploader-part {
  /* max-width: 325px; */
  /* height: 370px; */
  background: #ffffff;
  box-shadow: 0px 4px 8px rgb(0 0 0 / 8%);
  border-radius: 12.8359px;
}

.uploader-part-video {
  /* max-width: 325px; */
  /* height: 370px; */
  background: #ffffff;
  box-shadow: 0px 4px 8px rgb(0 0 0 / 8%);
  border-radius: 12.8359px;
}
.upload-text {
  padding: 20px;
  height: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
}
.product-form-wrapper {
  /* width: 675px;
    height: 540px; */
}

.product-uploader {
  /* min-width: 700px; */
  min-height: 370px;
}

img.upload-icon {
  margin-top: 23px;
  height: 60px;
  width: auto;
  text-align: center;
}

input#customRange3 {
  width: 400px;
}

p.slider-value {
  padding: 0 25px;
}

.radio-input {
  height: 25px;
  width: 20px;
  margin-left: 10px;
  margin-right: 10px;
  cursor: pointer;
}

.low-indicator {
  margin: 0 20px 0 20px;
}

.error-wrapper {
  color: #dd4848;
  font-size: 15px;
}

.list-shopify-products-wrapper {
  /* border-style: double; */
  height: 289px;
  scroll-behavior: smooth;
  overflow-y: auto;
  padding: 10px;
}

.small-image-list {
  width: 100%;
  height: auto;
  min-height: 41px;
  object-fit: cover;
  border-radius: 6.57074px;
}
.features.product-list-dynamic {
  padding: 0;
  background: transparent;
  width: 100%;
  display: block;
}
.product-form-wrapper {
  padding: 20px 50px 20px 50px;
  background: #fff;
  border-radius: 18px;
}
.list-shopify-products-wrapper .list-group-item,
.list-shopify-products-wrapper .card {
  border: none;
  border-radius: 0;
}
li.list-group-item p.product-title {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 4px;
  color: #181818;
  /* margin-left: 10px; */
  text-align: left;
}
li.list-group-item p.company {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #5c5c5c;
  word-break: break-word;
  margin-bottom: 0;
}
li.list-group-item p.price {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #5c5c5c;
  word-break: break-word;
  text-align: right;
  margin-bottom: 0;
}
li.list-group-item .product-item-content {
  align-items: center;
}
li.list-group-item .product---price {
  border-left: 0.821343px solid #ecf0ff;
}
.product-desc-wrapper textarea.form-control {
  min-height: 94px;
  padding: 16px;
  margin: 20px 0;
}
.product-information-wrapper h3.text-start {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  line-height: 45px;
  color: #111827;
  margin-bottom: 9px;
}
.product-information-wrapper p.text-start {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 170.23%;
  color: #374151;
  margin-bottom: 9px;
}
.product-information-wrapper {
  padding-bottom: 1.5rem;
}
.btn.btn-outline-primary.previous-button {
  min-width: 76px;
  min-height: 43px;
  margin: 0 5px;
  padding-top: 9px;
}
.button-wrapper-product .btn-outline-primary {
  color: #fff;
  border-color: #359cd7;
  background: #359cd7;
  border-radius: 6px;
  margin: 0 5px;
}
.button-wrapper-product button {
  margin-top: 20px;
  min-width: 76px;
  min-height: 43px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 170.23%;
}
.video-uploader > .form-group,
.image-uploader > .form-group,
.video-uploader > .form-group div,
.image-uploader > .form-group div {
  height: 100%;
}
.product-uploader h5.title {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: #181818;
  text-align: left;
  margin-bottom: 12px;
  width: 100%;
}
.product-uploader p.text {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 170.23%;
  color: #5c5c5c;
}
.product-uploader .upload-text {
  justify-content: space-between !important;
}
.product-uploader .bottom-wrapper {
  height: auto !important;
  min-height: 173px;
  border: 1px solid #f5f5f5;
  width: 100%;
}
.product-uploader .bottom-wrapper p {
  text-align: center;
  font-family: "Mulish";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 170.23%;
  color: rgba(0, 0, 0, 0.4);
  margin: 16px 0 0;
}
.features.product-list-dynamic .bottom-wrapper p {
  text-align: center;
}
.list-shopify-products-wrapper .list-group-item {
  cursor: pointer;
  padding: 16px;
}
.list-shopify-products-wrapper .list-group-item:hover {
  background-color: aliceblue;
}
.selected-product-add {
  background-color: rgba(53, 156, 215, 0.12);
}
.features.product-list-dynamic p {
  text-align: left;
}
.features.product-list-dynamic p.error-wrapper {
  margin-top: 15px;
}
.product-list-dynamic .list-shopify-products-wrapper .list-group-item:hover {
  background: aliceblue;
}
.optional-question {
  margin-bottom: 20px;
}
.normal_view_emails button.add_email_button {
  margin: 0 14px;
}
@media only screen and (max-width: 767px) {
  .product--name--wrapper {
    margin: 10px 0;
  }
  li.list-group-item .product--name--wrapper p.product-title {
    margin: 0;
  }
  li.list-group-item .product--price--wrapper p {
    text-align: left;
  }
  .password-information-wrapper {
    padding: 0;
  }
  .container.information-wrapper,
  .container.instruction-wrapper {
    padding-left: 0;
    padding-right: 0;
  }
}
@media only screen and (max-width: 767px) {
  div.product-form-wrapper {
    padding: 20px;
  }
  .product-form-wrapper .form-group.d-flex {
    flex-wrap: wrap;
  }
  .product-detail,
  .video-uploader {
    margin-top: 20px !important;
  }
}

.example-text-blip {
  cursor: pointer;
  color: #359cd7;
}

span.error_message {
  font-size: 14px;
  color: #dd5c5c;
}

.not_shipped_label {
  background: #dd5c5c;
  color: #fff;
  text-align: center;
  position: absolute;
  padding: 3px;
}

img.icon_delivery.delivered {
  height: 28px;
  width: 40px;
}

img.icon_delivery.not_delivered {
  height: 40px;
  width: 35px;
}
