.purebrand-toolbar {
  background: black;
  width: 100%;
  margin: auto;
  position: fixed;
  bottom: 0;
  height: 8vh;
  display: flex;
  align-items: center;
}
.record-msg {
  width: 30%;
  background: #231f20;
  color: white;
  font-style: italic;
  margin: auto;
}

.room-container-custom {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;
  height: 95vh;
  background: black;
}
