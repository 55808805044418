.demo-details-modal-header {
  display: flex;
  align-items: center;
  gap: 30px;
  flex-wrap: wrap;
}

.modal-demo-result {
  font-size: 20px;
  font-weight: 100;
}

.modal-demo-result img {
  vertical-align: sub;
}
