.dots-note-header {
  font-weight: bold;
}

.dots-fees-type {
  font-weight: 700;
}

.dots-agreement-container {
  margin-bottom: 30px;
}
