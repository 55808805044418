.host-connect-modal div.react-tel-input .flag-dropdown {
  border: 0;
}

.host-connect-modal .ant-modal-content {
  padding-top: 40px;
}

.host-connect-modal .btn.btn-primary {
  font-weight: normal;
}

.host-connect-modal .ant-form-item {
  margin-bottom: 20px;
}

.host-connect-modal .phone-label{
  margin-right: 5px;
}
