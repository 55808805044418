.create-app-modal .store-header {
  font-weight: 700;
  font-size: 20px;
}

.create-app-modal .store-name {
  font-size: 18px;
}

.create-app-modal .create-acc-btn {
  margin-top: 30px;
}
