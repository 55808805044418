.store-filter {
  background: white;
  display: flex;
  gap: 30px;
  border-radius: 10px;
  padding: 10px 0;
}

.predefined-store .ant-col {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.filter-modal {
  width: 800px !important;
}

.filter-modal .ant-modal-body {
  height: 400px;
}

.custom-store .ant-picker-date-panel {
  width: 240px;
}

.custom-store .ant-picker-range {
  min-width: 480px;
}

.filter-modal .stores-container .single-store-div {
  padding: 10px;
}

.stores-container {
  border-radius: 10px;
  background: white;
  height: 100%;
}

.stores-container .ant-input-group-wrapper {
  width: 100%;
}

.stores-container .ant-input-group-wrapper-outlined .ant-input-group-addon {
  background: white;
  border: none;
}

.stores-container .ant-input {
  border: none;
  height: 40px;
}

.filter-modal .ant-checkbox-group {
  height: 87%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  overflow-y: auto;
}

.filter-modal .ant-tabs .ant-tabs-tabpane {
  height: 100%;
}

.filter-modal .ant-tabs .ant-tabs-content {
  height: 100%;
}

.filter-modal .ant-tabs {
  height: 100%;
}

.filter-modal .stores-container .single-product-div {
  padding: 10px;
}

.filter-modal .stores-container hr {
  margin: 0;
}

.filter-modal .ant-input-outlined:focus {
  box-shadow: none;
}



@media (max-width: 760px) {
  .custom-store .ant-picker-date-panel {
    width: 230px;
  }

  .custom-store .ant-picker-range {
    min-width: 400px;
  }
}

@media (max-width: 760px) {
  .custom-store .ant-picker-date-panel {
    width: 230px;
  }

  .custom-store .ant-picker-range {
    min-width: 400px;
  }
}

@media (max-width: 665px) {
  .store-filter {
    flex-direction: column;
  }
}

@media (max-width: 490px) {
  .custom-store .ant-picker-date-panel {
    width: 250px;
  }

  .custom-store .ant-picker-range {
    min-width: 250px;
  }

  .custom-store .ant-picker-panels > *:first-child button.ant-picker-header-next-btn {
    visibility: visible;
  }

  .custom-store .ant-picker-panels > *:first-child button.ant-picker-header-super-next-btn {
    visibility: visible;
  }

  .custom-store .ant-picker-panels > *:last-child {
    display: none;
  }

  .custom-store .ant-picker-footer-extra > div {
    flex-wrap: wrap;
  }
}
