.settings-privacy-wrapper,
.settings-tandc-wrapper {
  padding: 35px 15px;
}
.nav-tabs {
  border: none;
}
.nav-tabs .nav-link {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #374151;
  background-color: transparent;
  border: 0;
  border-bottom: 1px solid #e5e7eb;
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #359cd7;
  background-color: transparent;
  border: 0;
  border-bottom: 1px solid #359cd7;
}
.tc-content {
  padding: 0 40px;
}
.venmo-selector-wrapper {
  max-width: 1200px;
}
@media only screen and (max-width: 768px) {
  div.tc-content {
    padding: 0 10px;
  }
  li.nav-item .nav-link {
    padding: 0.5rem;
  }
  .col-lg-6.col-md-6.col-sm-12.form--venmo-wrap {
    padding-top: 20px;
  }
}

div.venmo-wrapper {
  /* border-radius: 12px;
    box-shadow: 0px 5px 8px rgb(0 0 0 / 30%); */
  padding: 10px;
}
.payout-method-wrapper .btn.btn-outline-primary {
  background: #359cd7;
  border-radius: 15px;
  min-width: 120px;
  min-height: 40px;
  font-family: "Mulish";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  text-align: center;
  padding: 10px 20px;
  border: none;
  color: white;
}
img.venmo-logo-img {
  max-width: 250px;
  margin: 20px auto;
}
.venmo-form-wrapper {
  max-width: 700px;
  margin: 0 auto;
}

.foot-note {
  font-size: smaller;
}

.rounded-circle-venmo {
  border-radius: 50px !important;
  max-width: 200px;
  width: 100%;
  height: 200px;
  object-fit: cover;
}
.venmo-wrapper .image-view p {
  margin-top: 10px;
}
.custom-card-border.card {
  /* border-radius: 12px;
    box-shadow: 0px 5px 8px rgb(0 0 0 / 30%); */
  border: none;
  padding: 10px;
  min-height: 448.5px;
  height: 100%;
}

.venmo-form,
.venmo-selector-wrapper {
  height: 100%;
}
