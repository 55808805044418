.tc-headers {
  margin: 40px 0;
  text-decoration: underline;
}

.tc-main-container {
    scroll-behavior: smooth;
    max-height: calc(100vh - 260px);
    overflow-y: auto;
    padding-right: 20px;
}

.data-privacy-header {
    margin-top: 10px;
}
