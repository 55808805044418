.img {
  width: 100%;
  height: auto;
  border-radius: 7px;
  border: 2px solid black;
}

.upload-button {
  margin-top: 10px;
  display: none;
}

.form-outline svg {
  margin-right: 12px;
}

.form-outline {
  align-items: center;
}

.red-error {
  color: rgb(247, 82, 82);
  font-family: mulish;
}

.send-otp-wrap {
  font-size: 14px;
  font-family: "Mulish";
  font-weight: 700;
  line-height: 18px;
}

.uploader-parts {
  width: 100%;
  height: 100%;
  border-color: rgb(102, 102, 102);
  border-style: dotted;
  border-radius: 15px;
}

.dropzone {
  height: 100%;
  width: 100%;
}

.features.multi-step-form.t-c-acceptor {
  display: block;
  padding: 30px 15px;
}

.features.multi-step-form.t-c-acceptor .tc-acceptor-label {
  padding: 10px 40px 0;
}

.features.multi-step-form.t-c-acceptor .tc-acceptor-label label {
  display: flex;
  align-items: center;
}

.features.multi-step-form.t-c-acceptor
  .tc-acceptor-label
  label
  input[type="checkbox"] {
  width: 16px;
  height: 16px;
  margin-right: 8px;
}

@media only screen and (max-width: 768px) {
  .features.profile-info-form-first .container {
    max-width: 100%;
    padding: 0 10px;
  }
  div.features.multi-step-form.t-c-acceptor .tc-acceptor-label {
    padding: 22px 0px 0;
  }
}

@media only screen and (min-width: 768px) {
  .profile_picture_upload_wrap .upload-field,
  .profile_picture_wrap .form-outline {
    min-height: 490px;
    background: #ffffff;
    box-shadow: 0px 4px 6px -1px rgb(16 24 40 / 10%),
      0px 2px 4px -2px rgb(16 24 40 / 10%);
    border-radius: 8px;
    display: flex;
  }
}

.profile_picture_upload_wrap .upload-field,
.profile_picture_wrap .form-outline {
  background: #ffffff;
  box-shadow: 0px 4px 6px -1px rgb(16 24 40 / 10%),
    0px 2px 4px -2px rgb(16 24 40 / 10%);
  border-radius: 8px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.profile_picture_upload_wrap .upload-field,
.profile_picture_wrap .form-outline img {
  border: none;
}

.btn.send-otp-button {
  background-color: #359cd7;
  color: white;
  border: #359cd7;
  border-radius: 9px;
  min-height: 55px;
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  text-align: center;
  font-size: 14px;
  line-height: 40px;
  margin-left: 5px;
  min-width: 100px;
}

.response-otp-message p {
  font-size: 14px;
  line-height: 20px;
  margin-top: 10px;
}

.otp-btn-wrap.btn.btn-primary {
  font-size: 16px;
}

.select-type-percent select.form-control {
  border: 1px solid #ededed;
  border-radius: 15px;
  height: 55px;
  font-family: "Mulish";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #666161;
  appearance: auto;
}
