/* Google Font Import - Poppins */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

:root {
  /* ===== Colors ===== */
  --body-color: #e4e9f7;
  --sidebar-color: #fff;
  --primary-color: #359cd7;
  --primary-color-light: #f6f5ff;
  --toggle-color: #ddd;
  --text-color: #707070;

  /* ====== Transition ====== */
  --tran-03: all 0.2s ease;
  --tran-03: all 0.3s ease;
  --tran-04: all 0.3s ease;
  --tran-05: all 0.3s ease;
}

body {
  min-height: 100vh;
  background-color: #f8f8f8;
  transition: var(--tran-05);
}

::selection {
  background-color: var(--primary-color);
  color: #fff;
}

body.dark {
  --body-color: #18191a;
  --sidebar-color: #242526;
  --primary-color: #3a3b3c;
  --primary-color-light: #3a3b3c;
  --toggle-color: #fff;
  --text-color: #ccc;
}

/* ===== Sidebar ===== */
.sidebar {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  padding: 10px 0;
  background: var(--sidebar-color);
  transition: var(--tran-05);
  z-index: 100;
  width: 100%;
}
.sidebar.close {
  width: 88px;
}

/* ===== Reusable code - Here ===== */
.sidebar li {
  height: 50px;
  list-style: none;
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.sidebar header .image,
.sidebar .icon {
  min-width: 60px;
  border-radius: 6px;
}

.sidebar .icon {
  min-width: 60px;
  border-radius: 6px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
}

.sidebar .text,
.sidebar .icon {
  color: var(--text-color);
  transition: var(--tran-03);
}

.sidebar .text {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  opacity: 1;
  padding: 37px 0;
}
.sidebar.close .text {
  opacity: 0;
}
/* =========================== */

.sidebar header {
  position: relative;
}

.sidebar header .image-text {
  display: flex;
  align-items: center;
}
.sidebar header .logo-text {
  display: flex;
  flex-direction: column;
}
header .image-text .name {
  margin-top: 2px;
  font-size: 18px;
  font-weight: 600;
}

header .image-text .profession {
  font-size: 16px;
  margin-top: -2px;
  display: block;
}

.sidebar header .image {
  display: flex;
  align-items: center;
  justify-content: center;
}

.sidebar header .image img {
  width: 40px;
  border-radius: 6px;
}

.sidebar header .toggle {
  position: absolute;
  top: 50%;
  right: -25px;
  transform: translateY(-50%) rotate(180deg);
  height: 25px;
  width: 25px;
  background-color: var(--primary-color);
  color: var(--sidebar-color);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  cursor: pointer;
  transition: var(--tran-05);
}

body.dark .sidebar header .toggle {
  color: var(--text-color);
}

.sidebar.close .toggle {
  transform: translateY(-50%) rotate(0deg);
}

.sidebar li.search-box {
  border-radius: 6px;
  background-color: var(--primary-color-light);
  cursor: pointer;
  transition: var(--tran-05);
}

.sidebar li.search-box input {
  height: 100%;
  width: 100%;
  outline: none;
  border: none;
  background-color: var(--primary-color-light);
  color: var(--text-color);
  border-radius: 6px;
  font-size: 17px;
  font-weight: 500;
  transition: var(--tran-05);
}
.sidebar li a {
  list-style: none;
  height: 100%;
  background-color: transparent;
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  border-radius: 6px;
  text-decoration: none;
  transition: var(--tran-03);
}

.sidebar li a:hover {
  background-color: var(--primary-color);
}

.sidebar li a:active {
  background-color: #359cd7;
}
.sidebar li a:hover .icon,
.sidebar li a:hover .text {
  color: var(--sidebar-color);
}
body.dark .sidebar li a:hover .icon,
body.dark .sidebar li a:hover .text {
  color: var(--text-color);
}

.sidebar .menu-bar {
  height: calc(100vh - 180px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: scroll;
}
.menu-bar::-webkit-scrollbar {
  display: none;
}
.sidebar .menu-bar .mode {
  border-radius: 6px;
  background-color: var(--primary-color-light);
  position: relative;
  transition: var(--tran-05);
}

.menu-bar .mode .sun-moon {
  height: 50px;
  width: 60px;
}

.mode .sun-moon i {
  position: absolute;
}
.mode .sun-moon i.sun {
  opacity: 0;
}
body.dark .mode .sun-moon i.sun {
  opacity: 1;
}
body.dark .mode .sun-moon i.moon {
  opacity: 0;
}

.menu-bar .bottom-content .toggle-switch {
  position: absolute;
  right: 0;
  height: 100%;
  min-width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  cursor: pointer;
}
.toggle-switch .switch {
  position: relative;
  height: 22px;
  width: 40px;
  border-radius: 25px;
  background-color: var(--toggle-color);
  transition: var(--tran-05);
}

.switch::before {
  content: "";
  position: absolute;
  height: 15px;
  width: 15px;
  border-radius: 50%;
  top: 50%;
  left: 5px;
  transform: translateY(-50%);
  background-color: var(--sidebar-color);
  transition: var(--tran-04);
}

body.dark .switch::before {
  left: 20px;
}

.home {
  position: absolute;
  top: 0;
  top: 0;
  left: 250px;
  height: 100vh;
  width: calc(100% - 250px);
  background-color: var(--body-color);
  transition: var(--tran-05);
}
.home .text {
  font-size: 30px;
  font-weight: 500;
  color: var(--text-color);
  padding: 12px 60px;
}

.sidebar.close ~ .home {
  left: 78px;
  height: 100vh;
  width: calc(100% - 78px);
}
body.dark .home .text {
  color: var(--text-color);
}

img.logo-sidebar {
  max-width: 172px;
  width: 100%;
  height: auto;
}
.sidebar li a {
  padding: 20px;
}
.sidebar li {
  padding: 0;
}
ul.menu-links {
  padding: 0 30px;
}
li.nav-link button {
  background: transparent;
  border: none;
  font-size: 17px;
  font-weight: 500;
  color: #707070;
}
li.nav-link button span.nav-link {
  color: #707070;
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  padding: 0 28px;
}

.home-button::selection {
  fill: #48b2e4;
}

.clicker-paragraph {
  text-decoration: underline;
}

.main-row-wrapper {
  width: calc(100% - 0rem);
  margin: 0 auto;
}

/* Hamburger menu */
@media only screen and (max-width: 1100px) {
  .nav-container {
    position: relative;
    display: inline-block;
    max-width: 50px;
    max-height: 50px;
    overflow: visible;
    outline: none;
  }
  .nav-container.isactive .nav-toggle:before,
  .nav-container.isactive .nav-toggle:after {
    box-shadow: none;
  }
  .nav-container.isactive .nav-toggle:before {
    transform: rotate(-45deg);
  }
  .nav-container.isactive .nav-toggle:after {
    transform: rotate(45deg);
  }
  .nav-container.isactive .nav-items {
    transform: translate(0, 0);
  }
  .nav-container .nav-toggle {
    /* position: relative; */
    position: absolute;
    width: 34px;
    height: 34px;
    margin: 10px;
    z-index: 6;
    top: 15px;
  }
  div.right-content-wrapper {
    margin: 0px auto 0;
  }
  .sidebar-wrapper.nav-container {
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    z-index: 4;
  }
  div.row.user-wrapper {
    padding-left: 60px;
    align-items: center;
  }
  .nav-container .nav-toggle:hover {
    cursor: pointer;
  }
  .nav-container .nav-toggle:before,
  .nav-container .nav-toggle:after {
    content: "";
    position: absolute;
    top: 15px;
    left: 0;
    transform: translate(0, 0);
    width: 100%;
    height: 4px;
    background: #7c7c7c;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  .nav-container .nav-toggle:before {
    box-shadow: 0 9px 0 0 #7c7c7c;
  }
  .nav-container .nav-toggle:after {
    box-shadow: 0 -9px 0 0 #7c7c7c;
  }
  .nav-container .nav-items {
    position: absolute;
    top: 0;
    left: 0;
    min-width: 300px;
    max-width: max-content;
    width: 100vw;
    height: 100vh;
    z-index: 1;
    padding: 80px 20px 20px 10px;
    transition: transform 0.3s ease;
    transform: translate(calc(-100% - 50px), 0);
    background: #fff;
    grid-gap: 5px 0;
    align-content: start;
    box-shadow: 0 0 50px rgba(0, 0, 0, 0.3);
    z-index: 5;
    padding: 45px 0 0;
  }
  .nav-container .nav-items .nav-item {
    background: #e2e2e2;
    padding: 10px;
    transition: background-color 0.3s ease;
  }
  .nav-container .nav-items .nav-item:hover {
    cursor: pointer;
    background: #d6d6d6;
  }
  .sidebar header .image-text {
    width: 100%;
    padding: 0 35px;
  }
  .sidebar .menu-bar {
    height: calc(100vh - 210px);
  }
}

li.nav-link {
  cursor: pointer;
    transition: transform 0.3s ease;
}
li.nav-link:hover {
   transform: scale(1.09);
}
.active-item span.text.nav-text {
  color: #359cd7;
}

ul.notification-nav li.nav-link {
  color: #23265f;
}

img.logo-sidebar {
  cursor: pointer;
}

span.clicker-paragraph {
  margin: 0 5px;
  cursor: pointer;
}

.react-tel-input .form-control {
  width: 100%;
}
.react-tel-input {
  padding-right: 10px;
}
.not-verified-wrapper {
  text-align: center;
}
.not-verified-wrapper .spinner-grow {
  width: 10px;
  height: 10px;
  margin: 0 6px;
  background: #359cd7;
}

a.view-all-notification.dropdown-item {
  text-align: center;
  color: #359cd7;
}
.overlay.isactive {
  position: fixed;
  width: 100%;
  height: 100%;
  background: #00000059;
  z-index: 4;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
}
.sidebar-wrapper {
  background: #fff;
}

.sidebar-menu-item-text {
  font-family: "Mulish", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  opacity: 1;
}

.sidebar-menu-item-text-active{
  font-family: "Mulish", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  opacity: 1;
  color: #359cd7
}

@media only screen and (min-width: 1100px) {
  .sidebar header .image-text {
    justify-content: flex-start;
    padding: 0 30px;
  }
  .sidebar-wrapper {
    min-height: 100vh;
  }
  .sidebar.open.nav-items {
    position: fixed;
    width: 100%;
    max-width: max-content;
  }
}

.row.user-wrapper {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 3;
  background: #f8f8f8;
  padding: 20px 0;
}
@media only screen and (min-width: 1100px) and (max-width: 1200px) {
  nav.sidebar.open.nav-items {
    max-width: 230px;
  }
}
@media only screen and (min-width: 1100px) and (max-width: 1560px) {
  div.sidebar-wrapper {
    width: calc(15% - -61px);
  }
  div.right-content-wrapper {
    width: calc(85% - 85px);
    margin: 0 10px;
  }
}

p.user-status {
  margin-bottom: 0;
  padding-top: 0;
  font-weight: 400;
  color: #359cd7;
  text-align: left;
  text-indent: 40px;
}
