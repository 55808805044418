.filter-bar {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem; /* Spacing between filter items */
  padding: 1rem;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  max-width: 100%; /* Ensures responsiveness */
}
/* Allow the items to wrap within the container */
.filter-item {
  flex: 1 1 auto;
  min-width: 150px; /* Adjust based on design preference */
  padding: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

/* Optional: Add overflow to handle a large number of items */
.filter-bar.overflow {
  max-height: 200px; /* Or any preferred max height */
  overflow-y: auto;
}

.filters-title {
  color: #8dc3b6;
  background: inheritrans;
}

.filter-modal .ant-modal-content {
  background-color: #f3f3f3;
}

.filter-modal .ant-modal-header {
  background-color: #f3f3f3;
}

.filter-modal .ant-tabs .ant-tabs-tab {
  flex: auto;
  justify-content: center;
  background: white;
  border-radius: 5px;
}

.filter-modal .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list {
  width: 100%;
}

.filter-btns {
  display: flex;
  justify-content: space-between;
}

.filter-btns .clear-btn {
  background: grey;
  color: white;
  border: none;
  font-weight: normal;
}

.filter-btns .ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
  background: grey;
  color: white;
  border: none;
  font-weight: normal;
}

.right-btns {
  display: flex;
  gap: 15px;
}

@media (max-width: 500px) {
  .filter-btns {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
}

@media (max-width: 665px) {
  .filter-btns {
    margin-top: 40px;
  }

  .custom-dates .ant-space-item {
    padding: 0 1rem;
  }
}
