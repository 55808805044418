.photo-profile {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}
button#dropdown-basic {
  padding: 0;
}
.chart-host-wrapper,
.linechart-host-wrapper,
.showroom-wrapper,
.monthly-data-wrapper {
  margin-bottom: 20px;
}

.linechart-host-wrapper-full{
  margin-bottom: 20px;
  width: 100%;
}
.features.chart-host-wrapper {
  /* max-width: 300px; */
  min-height: 400px;
  /* margin: 20px 20px 20px 0px; */
  align-items: flex-start;
}
.chart-host-wrapper nav.nav {
  align-items: baseline;
  justify-content: space-between;
  width: 100%;
}
.chart-wrap {
  width: 100%;
}
.features.linechart-host-wrapper {
  /* max-width: 545px; */
  min-height: 400px;
  margin: 20px 0px 20px 0px;
}
.row.homepage-bottom-wrapper.d-flex {
  /* max-height: 500px; */
}

.features.homepage-product-list-wrapper {
  /* max-width: 500px; */
  /* min-height: 450px; */
}
.homepage-product-list-wrapper,
.purchase-rate-wrapper,
.request-wrapper,
.user-homepage-right-wrapper {
  margin-bottom: 15px;
}
.col.bottom-second-col {
  /* max-height: 500px; */
  /* max-width: 580px; */
}
.no-demo-wrap {
  text-align: center;
  margin-top: 20px;
}
.featuresd {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 52px 20px 64px 20px;
  height: 100%;
  background: #ffffff;
  border-radius: 18px;
}
.featuresd.purchase-rate-wrapper {
  max-height: 57px;
  margin-bottom: 15px;
}
.featuresd.request-wrapper {
  /* max-height: 320px; */
}
.dropdown-toggle::after {
  display: none;
}
.features.chart-host-wrapper .nav-link {
  font-family: "Mulish", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 9.85612px;
  line-height: 13px;
}
.features.chart-host-wrapper p {
  font-family: "Mulish", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 13.1415px;
  line-height: 16px;
}
.user-wrapper {
  margin-bottom: 20px;
}
.user-wrapper h3 {
  margin-bottom: 0px;
  font-family: "Mulish", sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  line-height: 24px;
}
.user-image-wrap {
  justify-content: flex-end;
  align-items: center;
}
@media only screen and (min-width: 1100px) and (max-width: 1220px) {
  img.logo-sidebar {
    max-width: 130px;
  }
}
.user-homepage-right-wrapper {
  background: #fff;
  padding: 20px;
  border-radius: 15px;
}

.bell-icon.dropdown {
  margin-right: 35px;
}
.btn-check:focus + .btn,
.btn:focus {
  box-shadow: none;
}

.homepage-white-wrap {
  flex-direction: row;
  align-items: center;
  padding: 20px;
  height: 100%;
  background: #ffffff;
  border-radius: 18px;
}

.homepage-product-list {
  min-height: 400px;
}

.purchase-rate-home {
  min-height: 110px;
}

.request-home {
  min-height: 275px;
}

h4.title-home-request {
  font-family: "Mulish", sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 18.0695px;
  line-height: 25px;
  text-align: center;
  color: #359cd7;
}

ul.chart-nav li.nav-link {
  padding: 0.5rem;
  font-family: "Mulish", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: #23265f;
}

.btn.btn-homepage-add {
  background: #359cd7;
  /* box-shadow: 0px 2px 1px rgb(0 0 0 / 12%); */
  border-radius: 15px;
  min-width: 120px;
  min-height: 40px;
  font-family: "Mulish", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  text-align: center;
  padding: 10px 10px;
  border: none;
  color: white;
}
button.btn.btn-homepage-add {
  max-width: 180px;
  width: 100%;
}
.product-list-dashboard nav.nav {
  display: block;
}
.demo-requests-home {
  height: 400px;
  overflow-y: auto;
}
.product-list-dashboard ul.list-group.list-group-flush {
  height: 400px;
  overflow-y: auto;
}
@media only screen and (max-width: 768px) {
  .user-homepage-right-wrapper {
    height: 100%;
    margin: 0 12px;
    width: calc(100% - 24px);
  }
}
@media only screen and (min-width: 1520px) {
  .host-dashboard-chart {
    /* height: 400px !important;
    width: 400px !important; */
    /* margin: 0 auto; */
  }
}
.showroom-data-tab .tab-nav-list {
  width: 100%;
}
.tab-nav-list nav.nav.d-flex {
  justify-content: space-between;
  align-items: center;
}
.tab-nav-list nav.nav.d-flex p {
  margin-bottom: 0;
}
.tab-nav-list ul.chart-nav {
  margin-bottom: 0;
}
.showroom-wrapper .column-wrapper,
.monthly-data-wrapper .column-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.requests-wrapper table.table tbody tr td a {
  margin: 0 3px;
}
.list-item-rating-dashboard {
  height: 360px;
  overflow-y: auto;
}
.dashboard-side-photo {
  height: 80px;
  width: 80px;
  border-radius: 50%;
  object-fit: cover;
}
.sdp--header__main > div p {
  font-family: "Mulish", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #23265f;
}
.sdp--text.sdp--date-btn__selected {
  background: #359cd7;
  border-radius: 30px;
  color: #fff;
}
button.sdp--square-btn.sdp--square-btn__shadowed.sdp--square-btn__outlined {
  border: 1px solid #23265f;
  border-radius: 50px;
  width: 24px;
  height: 24px;
}
button.sdp--square-btn.sdp--square-btn__shadowed.sdp--square-btn__outlined
  svg
  polyline {
  stroke: #23265f;
}
button.sdp--square-btn.sdp--square-btn__shadowed.sdp--square-btn__outlined svg {
  width: 16px;
  height: 16px;
}
.sdp--text {
  font-family: "Mulish", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: #181818;
}
.sdp {
  width: 100%;
  height: 100%;
}

.sdp--dates-grid {
  /* gap: 0px; */
}
.calendar-small-wrapper {
  min-height: 280px;
  max-width: 300px;
  margin: 0 auto;
}
.row.rating-title-wrap {
  align-items: center;
}
.title-rating-text p,
.row.rating-title-wrap .text-end p.text-end {
  margin-bottom: 0;
}
.title-rating.row {
  align-items: center;
  margin-bottom: 20px;
}
.row.rating-title-wrap .text-end p.text-end {
  font-family: "Mulish", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 24px;
  color: #b7b3af;
}
.user-smalldetail-wrapper p {
  font-family: "Mulish", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 13.5px;
  line-height: 18px;
  color: #747474;
}
.user-smalldetail-wrapper h4 {
  font-family: "Mulish", sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 22px;
  color: #181818;
  margin-top: 14px;
}
bdt.question,
[data-custom-class="title"],
[data-custom-class="title"] * {
  font-family: "Mulish", sans-serif !important;
  font-style: normal;
  font-weight: 400;
  line-height: 45px;
  color: #111827;
}
.showroom-data-tab .tab-nav-list li {
  padding: 0.5rem;
  padding: 0.5rem;
  font-family: "Mulish", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: #23265f;
}
.rbc-time-header.rbc-overflowing {
  margin-right: 7px !important;
}
.sugg-product-details p.product-title {
  font-size: 13px;
  font-family: "Mulish", sans-serif;
  font-weight: 700;
  margin-bottom: 8px;
}
.sugg-product-details {
  padding: 0 5px 0 0;
}
.sugg-product-details p.company {
  font-size: 13px;
  font-family: "Outfit";
}
.suggested-product-lists .list-group-item {
  min-height: 85px;
  height: 100%;
}
.suggested-product-lists .list-group-item .small-image-list {
  min-height: 40px;
}
td.image-title-wrapper {
  /* display: flex; */
  flex-wrap: wrap;
  align-items: center;
  min-width: 180px;
}
.accordion-button:focus {
  border-color: transparent;
  box-shadow: none;
}
.billing-page-wrapper table.table-payout.table td,
table.table-store.table td {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  font-feature-settings: "salt" on;
  color: #374151;
}
.billing-page-wrapper .inner-tab-payout .nav-tabs {
  border: 1px solid #f5f5f5;
  border-left: none;
  border-right: none;
}
td.no-data-wrap {
  border-bottom: none;
}
@media only screen and (max-width: 576px) {
  .suggested-product-lists .list-group-item {
    min-height: 100px;
  }
  div.suggested-product-lists {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .modal-dialog {
    max-width: 95%;
  }
}
@media only screen and (max-width: 1500px) {
  .sdp--square-btn {
    height: auto;
    width: auto;
    min-width: 10px;
    min-height: 10px;
    padding: 0 3px;
    font-size: 14px;
  }
}

p.status-product {
  font-size: x-small;
}
@media only screen and (max-width: 600px) {
  .user-wrapper h3 {
    font-size: 18px;
  }
  .features.inner-tab-payout {
    padding: 20px 16px 10px;
  }
  .setting-contact-form-wrapper form.contact-form {
    padding: 0;
  }
}

div.pending-status {
  background: #fcd34d;
  width: 60px;
  border-radius: 15px;
  text-align: center;
}
div.pending-status p {
  font-weight: 400;
  font-size: x-small;
  color: white;
}

div.active-status {
  background: #359cd7;
  width: 60px;
  border-radius: 15px;
  text-align: center;
}
div.active-status p {
  font-weight: 400;
  font-size: x-small;
  color: white;
}

.suggested-product-listing {
  margin-bottom: 15px;
  padding: 10px 10px;
  display: block;
  align-items: center;
  background: #ffffff;
  border-radius: 18px;
}

.d-flex.suggested-wrap {
  overflow-x: auto;
  padding: 10px 0;
}

p.suggested-product-title {
  margin-bottom: 0;
}

div.suggested-product-lists {
  cursor: pointer;
  margin: 3px;
}

p.company {
  font-family: "Mulish", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #5c5c5c;
  word-break: break-word;
  margin-bottom: 0;
}

div.list-group-item:hover {
  background: aliceblue;
}

@media only screen and (max-width: 1200px) {
  .admin-dashboard-list table.table {
    overflow: auto;
    display: block;
  }
}

div.calendar-small-wrapper .react-datepicker {
  width: 100%;
  border: 0.688119px solid #ededed;
  border-radius: 11.0099px;
  padding: 7px 0;
}
