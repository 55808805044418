@import url("https://fonts.googleapis.com/css2?family=Mulish:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Mulish:wght@700&family=Outfit:wght@400;500;700&display=swap");

.btn.btn-primary {
  background: #359cd7;
  /* box-shadow: 0px 2px 1px rgb(0 0 0 / 12%); */
  border-radius: 15px;
  min-width: 150px;
  min-height: 55px;
  font-family: "Mulish", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
  text-align: center;
  padding: 15px 10px;
  border: none;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Outfit", sans-serif;
}

.round-primary-btn {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  outline: none;
  border: none;
  background: gray;
}

.round-primary-btn > svg {
  fill: white;
  width: 20px;
  height: 20px;
}

.password-visibility {
  cursor: pointer;
  position: absolute;
  right: -2px;
  top: 17px;
}

.password-enter-wrapper {
  position: relative;
}

.form-outline .password-visibility svg {
  margin: 0;
}
.step-progress-bar {
  height: 16px;
  font-size: 12px;
  margin: 0 auto 15px;
}
.progress-bar.bg-info {
  background-color: #359cd7 !important;
}
div.product-review-modal .modal-content {
  border-radius: 20px;
  border: none;
}
/* venmo */
.venmo-form-wrapper input.form-control {
  border-radius: 15px;
  border: 1px solid #ededed;
  font-family: "Mulish", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #666161;
  padding: 16px;
  margin-bottom: 15px;
}
.col-2.venmo_form_username_col {
  padding-right: 10px;
}

.col-10.venmo_form_username_col {
  padding-left: 0;
}
input.form-control.forced_input_field {
  background-color: white;
  padding: 16px 5px;
  text-align: center;
}

@media only screen and (max-width: 767px) {
  .modal-content .button-save-photo button.btn.btn-outline-primary,
  .modal-content button.btn.btn-outline-secondary {
    width: 100%;
    max-width: 100%;
  }
  img.login-img {
    width: 100%;
    height: auto;
  }
  header nav.navbar .container {
    flex-wrap: wrap;
  }
}

p.error-message {
  color: rgb(230, 85, 32);
}

.custom-blink.spinner-grow {
  height: 0.5rem;
  width: 0.5rem;
  color: #359cd7;
}

div.spinner-dots {
  margin-left: 0.25rem;
}
.modal.show .modal-dialog {
  height: calc(100% - 20px);
}

.photo-viewer-full img.photo-full-view {
  width: 100%;
  object-fit: contain;
  max-width: 500px;
}

.carousel-control-next {
  max-width: max-content;
  height: fit-content;
}
.carousel-control-next,
.carousel-control-prev {
  transform: translate(-50%, -50%);
  top: 50% !important;
}
.carousel-control-prev {
  left: 30px !important;
  max-width: max-content;
  height: fit-content;
}

code.snippet_code {
  font-size: 0.875em;
  color: #359cd7;
  word-wrap: break-word;
}

#color1 {
  -webkit-appearance: none;
  padding: 0;
  border: none;
  border-radius: 10px;
  width: 20px;
  height: 20px;
  cursor: pointer;
}
#color1::-webkit-color-swatch {
  border: none;
  border-radius: 10px;
  padding: 0;
}
#color1::-webkit-color-swatch-wrapper {
  border: none;
  border-radius: 10px;
  padding: 0;
}

.code-title {
  background: #343541;
  display: flex;
}

p.code-language {
  padding: 0px 10px;
  margin: 0;
  color: #ffffff;
  width: 100%;
}
form.form-star {
  display: flex;
  align-items: center;
  margin: 0 5px;
}
button.copy-button {
  /* text-align: end; */
  /* width: 100%; */
  padding: 3px 5px;
  height: fit-content;
}
button.copy-button-dark {
  background: transparent;
  border: none;
  padding: 3px 5px;
  height: fit-content;
}

.duration-toolbar.snippet_toolbar .input-label:not(:last-child) {
  border-left: 1px solid #d9d9d9;
}
.fade.toast.message_toast.show {
  border: solid 5px;
  border-color: #359cd7;
  width: 100%;
}

span.redirect_page {
  font-size: 14px;
  font-weight: 500;
  color: #359cd7;
  cursor: pointer;
}

.d-flex.link_list_view {
  min-width: 250px;
  width: 100%;
  justify-content: space-between;
}

a.link_text_custom {
  font-family: "Mulish", sans-serif;
  color: #359cd7;
  text-decoration: none;
}

.custom-phone-ip input.form-control {
  height: 55px;
  border: none;
  border-radius: 15px;
}
