.missing-fields::before {
  display: inline;
  content: "⚠ ";
}

.link-color {
  text-decoration: none;
  color: black;
  cursor: pointer;
  max-width: max-content;
  margin-left: auto;
}

p.link-color:hover {
  text-decoration: underline;
}

.alternative span {
  color: rgb(29, 191, 219);
  cursor: pointer;
}
.alternative span:hover {
  text-decoration: underline;
}

img.login-img {
  max-width: 565px;
  border: none;
  border-radius: 20px;
  padding: 10px 0;
}
