.dots-coming-soon {
  text-align: center;
  font-family: "Mulish",Inter, sans-serif;
  font-size: 30px;
  font-weight: 700;
}

.dots-container div.react-tel-input .flag-dropdown {
  border-radius: 0px !important;
}

.dots-container .form-control {
  min-height: 40px !important;
}

.connect-dots {
  margin-top: 50px;
}

.dots-container .react-tel-input .form-control {
  border-radius: 3px;
}

.store-card {
  border: 1px solid grey;
  max-width: 500px;
  margin: auto;
  padding: 10px 0;
  border-radius: 10px;
  margin-top: 40px;
}

.store-card .seperator {
  height: 1px;
  border: 0.5px solid grey;
  margin: 10px 15px;
}

.store-card .connect-store-btn {
  border-radius: 5px;
  background: #ccc;
  color: black;
}

.store-card .store-name {
  font-weight: 700;
}

.payouts-text-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 25px;
  margin-top: 100px;
}

.payouts-text {
  color: #000;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-style: normal;
}

.go-dots-btn {
  width: 170px;
  height: 40px;
}

.verify-text {
  color: green;
}

.unverify-text {
  color: red;
}
