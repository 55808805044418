/* Download button styles */
.download-data-button {
  text-align: right;
}

.download-data-btn {
  min-width: 76px;
  min-height: 43px;
  margin: 0 5px;
  background-color: #359cd7;
  color: #fff;
}

.download-data-btn:not(:disabled):not(.ant-btn-disabled):hover {
  background-color: #359cd7;
  color: #fff;
}

/* Styling for filter bar */
.filter-bar {
  padding: 15px;
  border-radius: 8px;
  display: flex;
  flex-wrap: wrap;
}

.filter-bar select {
  font-size: 14px;
  padding: 10px;
  border-radius: 4px;
}

.filter-bar label {
  font-weight: 600;
}

.filter-bar .col {
  flex: 1 1 45%;
  margin: 5px 0;
}

@media (max-width: 768px) {
  .filter-bar .col {
    flex: 1 1 100%;
  }
}

/* Styling for summary section */
.summary-section {
  display: flex;
  justify-content: space-between;
  margin: 20px 0px;
  margin-top: 10px;
  flex-wrap: wrap;
  padding: 10px;
}

.summary-card {
  background-color: white;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
  border-radius: 15px;
  padding: 20px;
  flex: 1;
  text-align: center;
  margin-right: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.summary-card h5 {
  color: #558a6a;
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 10px;
}

.summary-card h2, .summary-card h3 {
  color: #293a75;
  font-weight: bold;
}

.summary-card h2 {
  font-size: 2.5rem;
}

.summary-card h3 {
  font-size: 1.5rem;
}

.summary-card p {
  color: #9aaf95;
  font-size: 0.875rem;
  margin-top: 5px;
}

/* Updated styles for chart container */
.summary-card .chart-container {
  height: 200px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.chart {
  width: 100%;
  height: 100%;
}

.summary-card:last-child {
  margin-right: 0;
}

/* Table container for horizontal scrolling */
.table-container {
  width: 100%;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
}

.table-wrapper {
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  width: 100%;
}

.table-payout {
  width: 100%; /* Full width on normal view */
  table-layout: auto; /* Allows the table to adjust column widths automatically */
  background-color: #ffffff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  border-collapse: collapse;
}

.table-payout th, .table-payout td {
  padding: 12px;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.table-payout th {
  background-color: #f4f6f8;
  color: #3c886b;
  font-weight: 600;
}

.table-payout td {
  color: #23265f;
}

/* Ensure enough width for larger screens */
@media (min-width: 1024px) {
  .table-payout {
    min-width: 1200px; /* Adjust this value based on content width requirements */
  }
}

.table-product p {
  width: fit-content;
  text-wrap: wrap;
}

.table-result {
  width: 12vw;
}

.no-data-wrap {
  text-align: center;
  padding: 20px;
  font-size: 16px;
  color: #999;
}

/* Buttons inside table */
.button-wrapper-product .btn {
  margin: 0 10px;
  padding: 10px 20px;
  border-radius: 6px;
}

.download-data-btn {
  background-color: #0069d9;
  color: #ffffff;
  padding: 10px 20px;
  border-radius: 6px;
  margin-top: 20px;
}

.download-data-btn:hover {
  background-color: #0056b3;
  color: #ffffff;
}

/* Responsiveness for table and buttons */
@media (max-width: 1024px) {
  /* Min-width removed so the table can scroll on smaller screens */
  .table-payout {
    min-width: unset;
  }
}

@media (max-width: 768px) {
  .table-payout th, .table-payout td {
    padding: 10px;
    font-size: 12px;
    max-width: 100px;
  }
}

@media (max-width: 480px) {
  .table-payout th, .table-payout td {
    padding: 8px;
    font-size: 11px;
    max-width: 80px;
  }
}

/* Filter container styles */
.filter-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  width: 100%;
  max-width: 800px;
}

.filter-item {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
  margin-right: 10px;
  background-color: #fff;
  width: 180px;
  justify-content: space-between;
}

.filter-label {
  color: #558a6a;
  font-size: 14px;
  font-weight: 500;
  margin-right: 10px;
}

.filter-value {
  color: #333;
  font-size: 14px;
  font-weight: 600;
  overflow: hidden;
}

.caret-icon {
  color: #558a6a;
}

/* Mobile responsiveness for summary cards */
@media (max-width: 768px) {
  .summary-card {
    width: 48%;
    margin-bottom: 10px;
  }
}

@media (max-width: 480px) {
  .summary-card {
    width: 100%;
    margin-bottom: 10px;
  }
}
