.login-link {
  color: rgb(29, 191, 219);
  cursor: pointer;
}
.login-link:hover {
  text-decoration: underline;
}

img.venmo_signup_logo {
  padding-left: 8px;
}
