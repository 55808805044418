.main-body {
  padding: 15px;
}
.cardc {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
}

.cardc {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0 solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1rem;
  margin-top: 10px;
}

.gutters-sm {
  margin-right: -8px;
  margin-left: -8px;
}

.gutters-sm > .col,
.gutters-sm > [class*="col-"] {
  padding-right: 8px;
  padding-left: 8px;
}
.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.bg-gray-300 {
  background-color: #e2e8f0;
}
.h-100 {
  height: 100% !important;
}
.shadow-none {
  box-shadow: none !important;
}
.user-profile-wrapper .btn.btn-outline-primary {
  margin: 0 5px 0 0;
}
.user-profile-wrapper img.rounded-circle {
  object-fit: cover;
  height: 150px;
  width: 150px;
  cursor: pointer;
}

.user-profile-wrapper img.edit-logo {
  object-fit: cover;
  height: 20px;
  width: 20px;
  cursor: pointer;
}
.user-profile-wrapper select.form-control {
  appearance: auto;
}

.user-profile-wrapper li.list-group-item {
  padding: 16px;
}
.user-profile-wrapper input.form-control,
.user-profile-wrapper select.form-control {
  border-radius: 15px;
  border: 1px solid #ededed;
  font-family: "Mulish", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #666161;
  padding: 16px;
}
.user-profile-wrapper textarea {
  padding: 16px;
}

button.remove_email_button {
  background: #ffffff;
  border-radius: 15px;
  min-width: 50px;
  font-family: "Mulish", sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 23px;
  text-align: center;
  padding: 15px 0px;
  border: solid;
  color: #359cd7;
}

button.add_email_button {
  background: #ffffff;
  border-radius: 15px;
  min-width: 130px;
  font-family: "Mulish", sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 23px;
  text-align: center;
  padding: 15px 10px;
  color: #359cd7;
  margin: 0 5px;
  border: solid;
}

button.submit_add_email_button {
  background: #359cd7;
  border-radius: 15px;
  min-width: 150px;
  font-family: "Mulish", sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 23px;
  text-align: center;
  padding: 15px 0px;
  color: #fff;
  margin: 0 0 10px 10px;
  border: none;
}

input.form-control.additional-email {
  max-width: 350px;
  margin: 10px;
}

.card.cardc.store_commission_card.mt-3 {
  max-width: 34rem;
  margin: 0 auto;
}

.col-sm-3.commission_setting_view_col {
  width: 40%;
}
.store_commission_card input.form-control,
.store_commission_card select.form-control {
  background-color: #f0f4f5;
  height: 50px;
}
table th {
  font-size: 1rem;
}
.normal_view_emails {
  margin-bottom: 20px;
}
@media only screen and (max-width: 600px) {
  li.notification-list-page.list-group-item {
    padding-left: 0;
  }
  button.add_email_button {
    font-size: 16px;
    line-height: 23px;
  }
  div.active-status,
  .pending-status {
    margin-top: 10px;
  }
  .venmo-selector-wrapper .custom-card-border.card,
  .venmo-selector-wrapper .custom-card-border.card .card-body {
    padding: 0;
  }
}

button.small_verify_email {
  border: none;
  border-radius: 12px;
  padding: 5px;
  font-size: 12px;
  color: #fcfcfc;
  background: #2d9dd7;
  font-weight: 800;
}

button.small_verify_email_unlink {
  border: solid;
  border-radius: 12px;
  padding: 5px;
  font-size: 12px;
  color: #2d9dd7;
  background: transparent;
  font-weight: 800;
}

.commission_info_percentage_text {
  display: flex;
  justify-content: end;
  font-size: 20px;
  font-weight: 500;
  color: black;
  align-items: center;
}

.commission_info_text {
  font-size: 14px;
  font-weight: normal;
  margin-left: 8px;
}

.commission_text {
  font-size: 18px;
}

.commission_info_div {
  margin-top: 4px;
  display: flex;
}
