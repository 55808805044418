.chart-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* padding: 5px */
    /* margin-top: -10px; */
  }
  
  .chart {
    width: 100%; /* Make the chart fill the container */
    /* height: 100%; Make the chart fill the container */
  }
  
  .summary-card:last-child {
    margin-right: 0;
  
}
.legend {
  display: flex;
  flex-wrap: wrap; /* Allow items to wrap to the next line */
  justify-content: center; /* Center the legends */
  gap: 10px; /* Add spacing between items */
  /* margin-top: 10px; Spacing above the legend */
  width: 100%; /* Ensure legend items take full container width */
}

.legend-item {
  display: flex;
  align-items: center;
  max-width: 150px; /* Prevent items from being too wide */
  margin: 0 5px; /* Add space between legend items */
  text-align: left;
}

.legend-color {
  width: 10px; /* Smaller color indicator */
  height: 10px;
  border-radius: 50%; /* Keep circular shape */
  margin-right: 5px;
  flex-shrink: 0; /* Prevent shrinking of the color indicator */
}

.legend-item span {
  font-size: 16px; /* Adjust font size for readability */
  white-space: nowrap; /* Prevent text from wrapping */
  overflow: hidden; /* Hide overflowing text */
  text-overflow: ellipsis; /* Add ellipses for truncated text */
}
