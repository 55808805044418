.main_video video {
  width: 140px !important;
  height: 293px !important;
  object-fit: cover;
  border-radius: 21px;
}

.requirements-section {
  margin-bottom: 15px;
}

.requirements-header {
  font-weight: bold;
  margin-bottom: 10px;
  color: #333;
}

.requirements-list {
  list-style-type: none;
  padding-left: 0;
}

.requirements-list li {
  margin-bottom: 10px;
}

.label {
  width: 150px;
  font-weight: 600;
  color: #359cd7;
}

.update-logo-button {
  font-weight: 600;
  color: white;
  font-size: 20px;
  width: 200px;
  height: 50px;
  border-radius: 8px;
  background-color: #1890ff;
  border-color: #1890ff;
}
