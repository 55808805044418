.compliance-modal {
  max-width: 1000px !important;
  margin: auto;
}

.compliance-modal .ant-row .custom-col {
  width: 48%;
  margin: 0 5px;
}

.compliance-modal .ant-row .custom-col-funds {
  width: 100%;
}

.compliance-modal .ant-picker {
  width: 100%;
}

.compliance-modal .ant-input-number {
  width: 100%;
}

.compliance-modal h3 {
  margin: 25px 0;
}

.director-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 50%;
  margin-bottom: 10px;
  margin-left: 5px;
}

.director-section .edit-btn {
  cursor: pointer;
  margin-right: 10px;
}

.director-section .delete-btn {
  cursor: pointer;
}

.submit-compliance-info {
  margin-top: 20px;
}

.compliance-modal div.react-tel-input .flag-dropdown {
  border-radius: 0 !important;
}

.compliance-modal .ant-input,
.compliance-modal .ant-select,
.compliance-modal .ant-picker {
  height: 38px;
}

.cancel-compliance-info {
  margin-right: 10px;
}

.learn-more-ubo {
  font-size: 14px;
}

@media only screen and (max-width: 600px) {
  .compliance-modal .ant-row .custom-col {
    width: 100%;
    margin: 0 5px;
  }
}
