.sidebar-menu-icon-container {
  height: 32px;
  width: 32px;
  background-color: #efefef;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sidebar-menu-item {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.sidebar-menu-icon-container-active {
  background-color: #359cd7;
}
