.directors-modal {
  width: 1000px !important;
}

.directors-modal .ant-input-number, .directors-modal .ant-picker {
  width: 100%;
}

.directors-modal .ant-modal-header {
  margin-bottom: 40px;
}

.directors-modal .ant-row {
  justify-content: space-between;
}

.directors-modal .ant-row .custom-col {
  width: 48%;
}

.directors-modal div.react-tel-input .flag-dropdown {
  border-radius: 0 !important;
}
