img.imageA {
  display: block;
}
.visually-hidden {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
}

/* Profile Section */

.profile {
  padding: 5rem 0;
}

.profile::after {
  content: "";
  display: block;
  clear: both;
}

.profile-image {
  /* float: left;
    width: calc(33.333% - 1rem); */
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 3rem;
}
.profile-image img {
  border-radius: 50%;
}
.profile-stats ul {
  padding: 0;
}
.profile-stats ul li {
  display: block;
  width: 100%;
}
.profile-user-settings {
  margin-top: 1.1rem;
}

.profile-user-name {
  display: inline-block;
  font-size: 3.2rem;
  font-weight: 300;
}

.profile-edit-btn {
  font-size: 1.4rem;
  line-height: 1.8;
  border: 0.1rem solid #dbdbdb;
  border-radius: 0.3rem;
  padding: 0 2.4rem;
  margin-left: 2rem;
}

.profile-settings-btn {
  font-size: 2rem;
  margin-left: 1rem;
}

.profile-stats {
  margin-top: 2.3rem;
}

.profile-stats li {
  font-size: 20px;
  line-height: 1.5;
  cursor: pointer;
  text-align: left;
}

.profile-stats li:last-of-type {
  margin-right: 0;
}

.profile-bio {
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.5;
  margin-top: 2.3rem;
}

.profile-real-name,
.profile-stat-count,
.profile-edit-btn {
  font-weight: 600;
}

/* Gallery Section */

.gallery {
  padding-bottom: 2rem;
  padding-top: 1rem;
}

.gallery-item {
  position: relative;
  /* flex: 1 0 22rem; */
  /* margin: 1rem; */
  color: #fff;
  cursor: pointer;
}
.gallery-item-likes {
  margin-right: 2.2rem;
}

.gallery-item-type {
  position: absolute;
  top: 1rem;
  right: 1rem;
  font-size: 2.5rem;
  text-shadow: 0.2rem 0.2rem 0.2rem rgba(0, 0, 0, 0.1);
}

.fa-clone,
.fa-comment {
  transform: rotateY(180deg);
}

.gallery-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

/* Loader */

.loader {
  width: 5rem;
  height: 5rem;
  border: 0.6rem solid #999;
  border-bottom-color: transparent;
  border-radius: 50%;
  margin: 0 auto;
  animation: loader 500ms linear infinite;
}
.gallery-item img.gallery-image {
  height: 270px;
  object-fit: cover;
}
.modal-header {
  align-items: baseline;
}
/* Media Query */

@media screen and (max-width: 40rem) {
  .profile {
    display: flex;
    flex-wrap: wrap;
    padding: 4rem 0;
  }

  .profile::after {
    display: none;
  }

  .profile-image,
  .profile-user-settings,
  .profile-bio,
  .profile-stats {
    float: none;
    width: auto;
  }

  .profile-image img {
    width: 7.7rem;
  }

  .profile-user-settings {
    flex-basis: calc(100% - 10.7rem);
    display: flex;
    flex-wrap: wrap;
    margin-top: 1rem;
  }

  .profile-user-name {
    font-size: 2.2rem;
  }

  .profile-edit-btn {
    order: 1;
    padding: 0;
    text-align: center;
    margin-top: 1rem;
  }

  .profile-edit-btn {
    margin-left: 0;
  }

  .profile-bio {
    font-size: 1.4rem;
    margin-top: 1.5rem;
  }

  .profile-edit-btn,
  .profile-bio,
  .profile-stats {
    flex-basis: 100%;
  }

  .profile-stats {
    order: 1;
    margin-top: 1.5rem;
  }

  .profile-stats li {
    font-size: 18px;
    flex: 1;
    margin: 0;
  }
}

/* Spinner Animation */

@keyframes loader {
  to {
    transform: rotate(360deg);
  }
}

/*

The following code will only run if your browser supports CSS grid.

Remove or comment-out the code block below to see how the browser will fall-back to flexbox & floated styling. 

*/

@supports (display: grid) {
  .profile {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-template-rows: repeat(3, auto);
    grid-column-gap: 3rem;
    align-items: center;
  }

  .profile-image,
  .profile-user-settings,
  .profile-stats,
  .profile-bio {
    margin: 0;
  }
  .profile-wrap .profile-image {
    justify-content: flex-start;
  }
  @media (max-width: 40rem) {
    .profile {
      grid-template-columns: auto 1fr;
      grid-row-gap: 1.5rem;
    }

    .profile-image {
      grid-row: 1 / 2;
    }

    .profile-user-settings {
      margin-top: 20px;
    }

    .profile-edit-btn,
    .profile-settings-btn,
    .profile-bio,
    .profile-stats {
      margin: 0;
    }
  }
}

.rating_final_button {
  background: #359cd7;
  border-radius: 15px;
  font-family: "Mulish", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #fff;
  text-align: center;
  border: none;
}
@media only screen and (max-width: 600px) {
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }
}

.schedule-status-wrapper.row.step-wrapper.show {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}

.schedule-image-wrapper img {
  border-radius: 5%;
  width: 100%;
  max-width: 250px;
  max-height: 250px;
}

.schedule-text-content {
  padding: 0 20px;
}

.heading-wrapper {
  font-family: "Mulish", sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 30px;
  color: #181818;
  margin-bottom: 9px;
}
.detail-wrapper p {
  font-family: "Mulish", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #5c5c5c;
  margin-bottom: 9px;
}

.logo-wrapper img {
  max-width: 200px;
  width: 100%;
  height: auto;
  cursor: pointer;
}

button.btn.btn-primary.mt-3.join-demo-button {
  margin-left: 5px;
}

.mt-3.l-3 {
  margin-left: 1rem;
}
