.product-review-modal {
  width: 100%;
}

.modal-content {
  width: 100%;
  border-radius: 1rem;
}

.modal-footer {
  padding: 0.25rem;
}

.modal-header {
  /* padding: 0.1rem 1rem */
}

.information-row {
  width: 50%;
}

.profile-picture-modal {
  height: 80px;
  width: 80px;
  border-radius: 20%;
  object-fit: cover;
}

.personal-info-row {
  align-items: center;
}

.request-detail-wrapper {
  padding: 1.25rem 0.5rem;
}

.pb-icon {
  height: 20px;
  width: 20px;
}

.progress-bar-custom {
  max-width: 200px;
  height: 10px;
}

.progress-bar-custom-wrap {
  display: flex;
  align-items: center;
}

.product-rev-name-detail {
  font-size: small;
}

.product-image-shopify {
  height: auto;
  width: 250px;
}

.product-image-review {
  padding: 0rem 2rem;
}

.detail-information-rating {
  color: #359cd7;
  cursor: pointer;
}

.list-questions {
  height: 277px;
  overflow-y: auto;
  scroll-behavior: smooth;
}

.list-questions ::-webkit-scrollbar {
  width: 8px;
  height: 4px;
}

/* Track */

.list-questions::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #ededed;
  border-radius: 4px;
}

/* Handle */

.list-questions::-webkit-scrollbar-thumb {
  background: #d7d7d7;
  border-radius: 4px;
}

.custom-calendar {
  position: relative;
  background: transparent;
  border: none;
  background-image: url(https://purebrand-storage.s3.us-east-2.amazonaws.com/calender.png);
  background-repeat: no-repeat;
  background-size: contain;
  width: 40px;
  height: 40px;
  margin-bottom: 10px;
  cursor: pointer;
}

input:focus-visible {
  outline: none;
}

.modal.show .modal-dialog {
  max-width: 800px;
  width: 100%;
  top: 10%;
  /* position: absolute;
    left: 50%;
    transform: translate(-50%, -50%); */
}

.modal.show .modal-dialog .modal-footer {
  padding: 0 10px 10px;
}

button.btn.btn-outline-secondary {
  height: 44px;
  border-radius: 8px;
  font-family: "Mulish";
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.02em;
}

div.modal-header {
  border-bottom: 0;
}

div.modal-footer {
  flex-direction: row-reverse;
  border-top: none;
  flex-wrap: nowrap;
}

.product-review-modal .modal-content {
  border-radius: 0;
  padding: 25px;
}

.modal-header .btn-close {
  padding: 0;
  margin: 0;
  /* position: absolute; */
  right: 30px;
  top: 30px;
}

.modal-content button.btn.btn-outline-primary {
  background: #359cd7;
  border-radius: 8px;
  color: #fff;
  font-family: "Mulish";
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.02em;
  border-color: #359cd7;
  max-width: 176px;
  width: 100%;
  height: 44px;
}

.modal-content button.btn.btn-outline-danger {
  background: transparent;
  border-radius: 8px;
  color: #cf2b2b;
  font-family: "Mulish";
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.02em;
  border-color: #cf2b2b;
  max-width: 176px;
  width: 100%;
  height: 44px;
}

.request-detail-wrapper p {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 170.23%;
}

p.detail-information-rating {
  text-decoration-line: underline;
  color: #359cd7;
}

.rating-view-details-wrap {
  display: flex;
  justify-content: space-between;
  max-width: 250px;
  padding-left: 40px;
}

/* reschedule */

.row.resechedule-wrapper {
  display: flex;
  flex-direction: column;
}

.select-date-wrapper {
  padding: 20px 16px;
}

input.popup-calendar {
  height: 45px;
  background: #ffffff;
  border-radius: 15px;
  border: 1px solid #ededed;
  padding: 16px;
  font-family: "Mulish";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #666161;
}

@media only screen and (max-width: 768px) {
  .logo-wrapperr {
    max-width: max-content;
    width: auto;
  }
  .modal.show .modal-dialog {
    margin: 10px auto;
  }
  .progress-bar-wrap {
    width: 80%;
  }
  .personal-info-row {
    flex-wrap: nowrap;
  }
  div.information-row {
    width: 100%;
  }
  .personal-info-row .picture-col {
    width: auto;
    max-width: max-content;
  }
  .product-image-review {
    padding: 0;
  }
  .product-image-shopify {
    width: 100%;
  }
}

p.small-button-text {
  font-size: x-small;
  color: #cf2b2b;
}

.text-secondary.red_pending {
  color: #b62223 !important;
}
