.bank-modal .ant-modal-header {
  margin-bottom: 40px;
}

.bank-modal .ant-input-number {
  width: 100%;
}

.bank-modal .ant-input,
.bank-modal .ant-select {
  height: 40px;
}
