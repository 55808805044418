header .Header-img {
  height: auto;
  max-width: 189px;
  width: 100%;
}
img.Header-img.murphy_door {
  max-width: 450px;
  width: 100%;
}
footer .Header-img {
  height: auto;
  max-width: 178px;
}
header .header-signup {
  background: #359cd7;
  /* box-shadow: 0px 2px 1px rgb(0 0 0 / 12%); */
  border-radius: 15px;
  min-width: 150px;
  min-height: 55px;
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
  text-align: center;
  padding: 15px 10px;
  border: none;
}
.header-login {
  background: #ffffff;
  border: 1px solid #359cd7;
  color: #359cd7;
  /* box-shadow: 0px 2px 1px rgb(0 0 0 / 12%); */
  border-radius: 15px;
  min-width: 150px;
  min-height: 55px;
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
  text-align: center;
  padding: 15px 10px;
  margin-left: 15px;
}
.btn-check:active + .btn-outline-primary:focus,
.btn-check:checked + .btn-outline-primary:focus,
.btn-outline-primary.active:focus,
.btn-outline-primary.dropdown-toggle.show:focus,
.btn-outline-primary:active:focus {
  box-shadow: none;
}
nav.navbar {
  padding: 25px 0;
}
span.navbar-brand p {
  font-family: "Outfit";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: -0.011em;
  color: rgba(0, 0, 0, 0.8);
  margin-bottom: 0;
}

.pb-logo-header {
  cursor: pointer;
}
