.products-container {
  border-radius: 10px;
  background: white;
  height: 100%;
}

.products-container .ant-input-group-wrapper {
  width: 100%;
}

.products-container .ant-input-group-wrapper-outlined .ant-input-group-addon {
  background: white;
  border: none;
}

.products-container .ant-input {
  border: none;
  height: 40px;
}

.filter-modal .ant-checkbox-group {
  height: 87%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  overflow-y: auto;
}

.filter-modal .ant-tabs .ant-tabs-tabpane {
  height: 100%;
}

.filter-modal .ant-tabs .ant-tabs-content {
  height: 100%;
}

.filter-modal .ant-tabs {
  height: 100%;
}

.filter-modal .products-container .single-product-div {
  padding: 10px;
}

.filter-modal .products-container hr {
  margin: 0;
}

.filter-modal .ant-input-outlined:focus {
  box-shadow: none;
}
